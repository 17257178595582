import { Form } from "react-router-dom";
import styles from "./quickSettings.module.css";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../../utilities/utilities";
import { getWords, getUserStats } from "../../loaders/loaders";
import { updateSettings } from "../../actions/actions";
import { lastTwentyTestsAverages } from "../../utilities/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export const QuickSettings = ({
      settings,
      setSettings,
      setAllWords,
      setWordIndex,
      statsData,
      setShowParagraphLoader,
      setStatsData,
      testStarted,
      userDetails,
}) => {
      const settingsRef = useRef(null);
      const wordsChangeHandler = async (event) => {
            event.stopPropagation();
            const value = JSON.parse(event.target.value);

            if (userDetails) {
                  const responseOne = getWords({
                        request: {
                              url: `http://localhost:3000?language=${value.language}&optionIndex=${value.optionIndex}`,
                        },
                  });
                  const responseTwo = getUserStats({
                        request: {
                              url: `http://localhost:3000/userStats?language=${value.language}&optionIndex=${value.optionIndex}`,
                        },
                  });
                  setShowParagraphLoader(true);

                  const responses = await Promise.allSettled([
                        responseOne,
                        responseTwo,
                  ]);
                  if (
                        responses[0].value.status === "success" &&
                        responses[1].value.status === "success"
                  ) {
                        setAllWords(responses[0].value.payload);
                        setStatsData(responses[1].value.payload);
                        setWordIndex(0);

                        setSettings((previous) => {
                              return {
                                    ...previous,
                                    modeThree: 1000,
                                    "language and range": value,
                              };
                        });
                        setShowParagraphLoader(false);
                        const data = await updateSettings({
                              [event.target.name]: JSON.stringify(value),
                        });
                        if (data.status === "error") {
                              toast.error(
                                    data.message + ",could not update settings",
                                    toastOptions
                              );
                        }
                  } else {
                        if (responses[0].value.status === "error") {
                              toast.error(
                                    responses[0].value.message,
                                    toastOptions
                              );
                        } else {
                              toast.error(
                                    responses[1].value.message,
                                    toastOptions
                              );
                        }

                        setShowParagraphLoader(false);
                  }
            } else {
                  setShowParagraphLoader(true);
                  const responseOne = await getWords({
                        request: {
                              url: `http://localhost:3000?language=${value.language}&optionIndex=${value.optionIndex}`,
                        },
                  });
                  if (responseOne.status === "success") {
                        setAllWords(responseOne.payload);
                        setWordIndex(0);
                        setSettings((previous) => {
                              return {
                                    ...previous,
                                    modeThree: 1000,
                                    "language and range": value,
                              };
                        });
                        setShowParagraphLoader(false);
                  } else {
                        toast.error(responseOne.message, toastOptions);
                        setShowParagraphLoader(false);
                  }
            }
      };
      const [speedDistribution, setSpeedDistribution] = useState({
            leastValue: 1000,
            speeds: {},
      });

      useEffect(() => {
            const testMode = statsData.testMode;
            const speedDistribution = { leastValue: 1000, speeds: {} };
            if (testMode?.wordsStats) {
                  Object.values(testMode.wordsStats).forEach((value) => {
                        [
                              value.lastTwentyTestsAverageWpm,
                              value.lastTwentyTestsAverageAccuracy,
                        ] = lastTwentyTestsAverages(value.lastTwentyTests);
                        for (let i = 30; i < 300; i += 10) {
                              if (value.lastTwentyTestsAverageWpm < i) {
                                    if (i < speedDistribution.leastValue) {
                                          speedDistribution.leastValue = i;
                                    }
                                    if (
                                          speedDistribution.speeds[i] ===
                                          undefined
                                    ) {
                                          speedDistribution.speeds[i] = 1;
                                    } else {
                                          speedDistribution.speeds[i]++;
                                    }
                              }
                        }
                  });
                  setSpeedDistribution(speedDistribution);
                  if (
                        !speedDistribution.speeds[settings.modeThree] &&
                        settings.modeThree !== 1000
                  ) {
                        setSettings((previous) => {
                              return {
                                    ...previous,
                                    modeThree: speedDistribution.leastValue,
                              };
                        });
                  }
            } else {
                  setSettings((previous) => {
                        return {
                              ...previous,
                              modeThree: 1000,
                        };
                  });
            }
      }, []);

      const settingsChangeHandler = async (event) => {
            let name = event.target.name;
            setSettings((previous) => {
                  return {
                        ...previous,
                        [name]: event.target.value,
                  };
            });

            if (name === "modeThree" || name === "mode") {
                  setWordIndex(0);
            }
            if (userDetails) {
                  const data = await updateSettings({
                        [event.target.name]: event.target.value,
                  });
                  if (data.status === "error") {
                        toast.error(data.message, toastOptions);
                  }
            }
      };

      return (
            <>
                  <Form
                        method="POST"
                        action="/settings"
                        onChange={settingsChangeHandler}
                        className={styles["settings-form"]}
                        style={testStarted ? { opacity: 0 } : null}
                  >
                        <FontAwesomeIcon
                              icon={faArrowLeft}
                              onClick={() => {
                                    settingsRef.current.scrollBy({
                                          left: -200,
                                    });
                              }}
                              style={{ color: "white", cursor: "pointer" }}
                        ></FontAwesomeIcon>
                        <section
                              ref={settingsRef}
                              className={
                                    styles["filtering-section"] +
                                    " " +
                                    styles[
                                          `filtering-section-${settings.theme}`
                                    ]
                              }
                              tabIndex={-1}
                        >
                              {/* mode */}
                              <select
                                    aria-label="mode"
                                    className={styles["select"]}
                                    name="mode"
                                    value={settings.mode}
                              >
                                    <option disabled>MODE</option>
                                    <option value="test">speed mode</option>
                                    <option value="practise">
                                          practise mode
                                    </option>
                                    <option value="beginner">
                                          beginner mode
                                    </option>
                              </select>

                              {/* language and range */}

                              {settings.mode === "test" ||
                              settings.mode === "practise" ? (
                                    <select
                                          aria-label="language and range"
                                          className={styles["select"]}
                                          name="language and range"
                                          onChange={wordsChangeHandler}
                                          value={JSON.stringify(
                                                settings["language and range"]
                                          )}
                                    >
                                          <option disabled>
                                                languages and ranges
                                          </option>
                                          <option
                                                value={JSON.stringify({
                                                      language: "english",
                                                      fullName: "english (1-100)",
                                                      optionIndex: 0,
                                                })}
                                          >
                                                english (1-100)
                                          </option>
                                          <option
                                                value={JSON.stringify({
                                                      language: "english",
                                                      fullName: "english (101-200)",
                                                      optionIndex: 1,
                                                })}
                                          >
                                                english (101-200)
                                          </option>
                                          <option
                                                value={JSON.stringify({
                                                      language: "english",
                                                      fullName: "english (201-300)",
                                                      optionIndex: 2,
                                                })}
                                          >
                                                english (201-300)
                                          </option>
                                          <option
                                                value={JSON.stringify({
                                                      language: "english",
                                                      fullName: "english (1-300)",
                                                      optionIndex: 3,
                                                })}
                                          >
                                                english (1-300)
                                          </option>
                                          <option
                                                value={JSON.stringify({
                                                      language: "english",
                                                      fullName: "english (1-60 words + all numbers(1-30))",
                                                      optionIndex: 4,
                                                })}
                                          >
                                                english (1-60 words + all
                                                numbers(1-30))
                                          </option>
                                          <option
                                                value={JSON.stringify({
                                                      language: "english",
                                                      fullName: "english (all numbers(1-30))",
                                                      optionIndex: 5,
                                                })}
                                          >
                                                english (all numbers(1-30))
                                          </option>
                                          <option
                                                value={JSON.stringify({
                                                      language: "english",
                                                      fullName: " english (1-60 words + all special characters(1-31))",
                                                      optionIndex: 6,
                                                })}
                                          >
                                                english (1-60 words + all
                                                special characters(1-31))
                                          </option>
                                          <option
                                                value={JSON.stringify({
                                                      language: "english",
                                                      fullName: "english (1-300 words + all special characters(1-31))",
                                                      optionIndex: 7,
                                                })}
                                          >
                                                english (1-300 words + all
                                                special characters(1-31))
                                          </option>
                                          <option
                                                value={JSON.stringify({
                                                      language: "english",
                                                      fullName: " english (1-300 words + all numbers(30))",
                                                      optionIndex: 8,
                                                })}
                                          >
                                                english (1-300 words + all
                                                numbers(1-30))
                                          </option>
                                          <option
                                                value={JSON.stringify({
                                                      language: "english",
                                                      fullName: "  english (1-300 words + all special characters(1-31)+all numbers(1-30))",
                                                      optionIndex: 9,
                                                })}
                                          >
                                                english (1-300 words +all
                                                numbers(1-30) + all special
                                                characters(1-31))
                                          </option>
                                    </select>
                              ) : null}

                              {/* mode two */}
                              {settings.mode === "practise" &&
                              settings.modeOne === "words" ? (
                                    <select
                                          aria-label="mode two"
                                          className={styles["select"]}
                                          name="modeTwo"
                                          value={settings.modeTwo}
                                    >
                                          <option disabled>MODE TWO</option>
                                          <option value="1">
                                                include random word
                                          </option>
                                          <option value="0">
                                                no random word
                                          </option>
                                    </select>
                              ) : null}

                              {/* mode three */}
                              {settings.mode === "practise" &&
                              settings.modeOne === "words" ? (
                                    <select
                                          aria-label="mode three"
                                          className={styles["select"]}
                                          name="modeThree"
                                          onChange={(event) => {
                                                if (!userDetails) {
                                                      toast.error(
                                                            "please login to use this feature",
                                                            toastOptions
                                                      );
                                                      event.stopPropagation();
                                                } else if (
                                                      !statsData?.testMode
                                                ) {
                                                      toast.error(
                                                            "no words data exists,please take some tests to generate data",
                                                            toastOptions
                                                      );
                                                      event.stopPropagation();
                                                }
                                          }}
                                          value={settings.modeThree}
                                    >
                                          {userDetails &&
                                          statsData?.testMode ? (
                                                <>
                                                      <option disabled>
                                                            FILTER BY SPEED
                                                      </option>
                                                      {Object.entries(
                                                            speedDistribution.speeds
                                                      ).map(([key, value]) => {
                                                            return (
                                                                  <option
                                                                        value={
                                                                              key
                                                                        }
                                                                  >
                                                                        &lt;{" "}
                                                                        {key}{" "}
                                                                        wpm (
                                                                        {value})
                                                                  </option>
                                                            );
                                                      })}
                                                      <option value={1000}>
                                                            All
                                                      </option>
                                                </>
                                          ) : (
                                                <>
                                                      <option disabled>
                                                            FILTER BY SPEED
                                                      </option>

                                                      <option value={30}>
                                                            &lt; {30} wpm ({0})
                                                      </option>
                                                      <option value={40}>
                                                            &lt; {40} wpm ({0})
                                                      </option>
                                                      <option value={50}>
                                                            &lt; {50} wpm ({0})
                                                      </option>
                                                      <option value={60}>
                                                            &lt; {60} wpm ({0})
                                                      </option>
                                                      <option value={70}>
                                                            &lt; {70} wpm ({0})
                                                      </option>
                                                      <option value={80}>
                                                            &lt; {80} wpm ({0})
                                                      </option>
                                                      <option value={90}>
                                                            &lt; {90} wpm ({0})
                                                      </option>
                                                      <option value={30}>
                                                            &lt; {90} wpm ({0})
                                                      </option>
                                                      <option value={30}>
                                                            &lt; {100} wpm ({0})
                                                      </option>
                                                      <option value={30}>
                                                            &lt; {110} wpm ({0})
                                                      </option>
                                                      <option value={30}>
                                                            &lt; {120} wpm ({0})
                                                      </option>
                                                      <option value={30}>
                                                            &lt; {130} wpm ({0})
                                                      </option>
                                                      <option value={30}>
                                                            &lt; {140} wpm ({0})
                                                      </option>
                                                      <option value={30}>
                                                            &lt; {150} wpm ({0})
                                                      </option>
                                                      <option value={30}>
                                                            &lt; {160} wpm ({0})
                                                      </option>
                                                      <option value={1000}>
                                                            All
                                                      </option>
                                                </>
                                          )}
                                    </select>
                              ) : null}

                              <select
                                    aria-label="timer"
                                    className={styles["select"]}
                                    name="timer"
                                    value={settings.timer}
                              >
                                    <option value="" disabled>
                                          TIMER
                                    </option>
                                    {/* <option value="5">5 seconds</option> */}
                                    <option value="15">15 seconds</option>
                                    <option value="30">30 seconds</option>
                                    <option value="60">60 seconds</option>
                              </select>

                              <select
                                    aria-label="theme"
                                    className={styles["select"]}
                                    name="theme"
                                    value={settings.theme}
                              >
                                    <option value="" disabled>
                                          THEME
                                    </option>
                                    <option value="theme-2">theme-1</option>
                                    <option value="theme-1">theme-2</option>
                                    <option value="theme-3">theme-3</option>
                                    <option value="theme-4">theme-4</option>
                                    <option value="theme-5">theme-5</option>
                                    <option value="theme-6">theme-6</option>
                                    <option value="theme-7">theme-7</option>
                              </select>
                              <select
                                    aria-label="text effect"
                                    className={styles["select"]}
                                    name="textEffect"
                                    value={settings.textEffect}
                              >
                                    <option value="" disabled>
                                          TEXT EFFECT
                                    </option>
                                    <option value="text-effect-1">
                                          text effect-1
                                    </option>
                                    <option value="text-effect-2">
                                          text effect-2
                                    </option>
                                    <option value="text-effect-3">
                                          text effect-3
                                    </option>
                              </select>

                              {/* sound */}
                              <select
                                    aria-label="sounds"
                                    className={styles["select"]}
                                    name="sound"
                                    value={settings.sound}
                              >
                                    <option disabled value="none">
                                          sound
                                    </option>
                                    <option value="none">none</option>
                                    <option value="soundA">sound A</option>
                                    <option value="soundB">sound B</option>
                                    <option value="soundC">sound c</option>
                              </select>
                        </section>
                        <FontAwesomeIcon
                              icon={faArrowRight}
                              onClick={() => {
                                    settingsRef.current.scrollBy({
                                          left: 200,
                                    });
                              }}
                              style={{ color: "white", cursor: "pointer" }}
                        ></FontAwesomeIcon>
                  </Form>
            </>
      );
};
