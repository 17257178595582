import styles from "./beginnerMode.module.css";

import { ClosingButton } from "../../components/buttons/closingButton";
import { beginnerModeData } from "../../utilities/utilities";
import whichFingers from "../../assets/images/which_fingers.png";
import { useState } from "react";
import { ImageOverlay } from "./imageOverlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
export const BeginnerModeChallenges = ({
      theme,
      setShowLessons,
      setSettings,
      beginnerModeStats,
}) => {
      const closingButtonClickHandler = () => {
            setShowLessons(false);
      };

      const [showImageOverlay, setShowImageOverlay] = useState(false);
      const [showVideoOverlay, setShowVideoOverlay] = useState(false);

      const alphabetsOnClickHandler = (event) => {
            const value = event.target.getAttribute("data-value");
            console.log(event.target, value);
            setSettings((previous) => {
                  return { ...previous, modeFour: value };
            });
      };

      const lessons = beginnerModeStats.lessons;

      return (
            <>
                  <aside className={styles["beginner-mode-challenges"]}>
                        <ClosingButton
                              clickHandler={closingButtonClickHandler}
                        ></ClosingButton>
                        <h1 style={{ textAlign: "center", color: "white" }}>
                              LESSONS
                        </h1>

                        <div className={styles["challenges-sub-type"]}>
                              <h4 className={styles["sub-type-heading"]}>
                                    Introduction
                              </h4>
                              <div className={styles["challenges"]}>
                                    <div
                                          className={
                                                styles["challenge"] +
                                                " " +
                                                styles["challenge-" + theme]
                                          }
                                          onClick={() => {
                                                setShowVideoOverlay(true);
                                          }}
                                    >
                                          Home row (must watch video)
                                    </div>
                              </div>
                              <div className={styles["challenges"]}>
                                    <div
                                          className={
                                                styles["challenge"] +
                                                " " +
                                                styles["challenge-" + theme]
                                          }
                                          onClick={() => {
                                                setShowImageOverlay(true);
                                          }}
                                    >
                                          key to finger mapping
                                    </div>
                              </div>
                        </div>
                        <div className={styles["challenges-sub-type"]}>
                              <h4 className={styles["sub-type-heading"]}>
                                    Alphabet keys
                              </h4>
                              <div
                                    className={styles["challenges"]}
                                    onClick={alphabetsOnClickHandler}
                              >
                                    {beginnerModeData
                                          .slice(0, 15)
                                          .map((current, index) => {
                                                return (
                                                      <div
                                                            className={
                                                                  styles[
                                                                        "challenge"
                                                                  ] +
                                                                  " " +
                                                                  styles[
                                                                        "challenge-" +
                                                                              theme
                                                                  ]
                                                            }
                                                            data-value={index}
                                                      >
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-number"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index
                                                                  }
                                                            >
                                                                  {index + 1}.
                                                            </div>
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-name"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index
                                                                  }
                                                            >
                                                                  {current.name}
                                                            </div>
                                                            {lessons &&
                                                            lessons[
                                                                  current
                                                                        .lessonId
                                                            ]?.testsAttempted >
                                                                  0 ? (
                                                                  <FontAwesomeIcon
                                                                        icon={
                                                                              faCircleCheck
                                                                        }
                                                                  />
                                                            ) : null}
                                                      </div>
                                                );
                                          })}
                              </div>
                        </div>

                        <div className={styles["challenges-sub-type"]}>
                              <h4 className={styles["sub-type-heading"]}>
                                    Words
                              </h4>
                              <div
                                    className={styles["challenges"]}
                                    onClick={alphabetsOnClickHandler}
                              >
                                    {beginnerModeData
                                          .slice(15, 20)
                                          .map((current, index) => {
                                                return (
                                                      <div
                                                            className={
                                                                  styles[
                                                                        "challenge"
                                                                  ] +
                                                                  " " +
                                                                  styles[
                                                                        "challenge-" +
                                                                              theme
                                                                  ]
                                                            }
                                                            data-value={
                                                                  index + 15
                                                            }
                                                      >
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-number"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index +
                                                                        15
                                                                  }
                                                            >
                                                                  {index +
                                                                        1 +
                                                                        15}
                                                                  .
                                                            </div>
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-name"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index +
                                                                        15
                                                                  }
                                                            >
                                                                  {current.name}
                                                            </div>
                                                            {lessons &&
                                                            lessons[
                                                                  current
                                                                        .lessonId
                                                            ]?.testsAttempted >
                                                                  0 ? (
                                                                  <FontAwesomeIcon
                                                                        icon={
                                                                              faCircleCheck
                                                                        }
                                                                  />
                                                            ) : null}
                                                      </div>
                                                );
                                          })}
                              </div>
                        </div>

                        <div className={styles["challenges-sub-type"]}>
                              <h4 className={styles["sub-type-heading"]}>
                                    Number keys
                              </h4>
                              <div
                                    className={styles["challenges"]}
                                    onClick={alphabetsOnClickHandler}
                              >
                                    {beginnerModeData
                                          .slice(20, 27)
                                          .map((current, index) => {
                                                return (
                                                      <div
                                                            className={
                                                                  styles[
                                                                        "challenge"
                                                                  ] +
                                                                  " " +
                                                                  styles[
                                                                        "challenge-" +
                                                                              theme
                                                                  ]
                                                            }
                                                            data-value={
                                                                  index + 20
                                                            }
                                                      >
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-number"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index +
                                                                        20
                                                                  }
                                                            >
                                                                  {index +
                                                                        1 +
                                                                        20}
                                                                  .
                                                            </div>
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-name"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index +
                                                                        20
                                                                  }
                                                            >
                                                                  {current.name}
                                                            </div>
                                                            {lessons &&
                                                            lessons[
                                                                  current
                                                                        .lessonId
                                                            ]?.testsAttempted >
                                                                  0 ? (
                                                                  <FontAwesomeIcon
                                                                        icon={
                                                                              faCircleCheck
                                                                        }
                                                                  />
                                                            ) : null}
                                                      </div>
                                                );
                                          })}
                              </div>
                        </div>

                        <div className={styles["challenges-sub-type"]}>
                              <h4 className={styles["sub-type-heading"]}>
                                    Special keys - 1
                              </h4>
                              <div
                                    className={styles["challenges"]}
                                    onClick={alphabetsOnClickHandler}
                              >
                                    {beginnerModeData
                                          .slice(27, 40)
                                          .map((current, index) => {
                                                return (
                                                      <div
                                                            className={
                                                                  styles[
                                                                        "challenge"
                                                                  ] +
                                                                  " " +
                                                                  styles[
                                                                        "challenge-" +
                                                                              theme
                                                                  ]
                                                            }
                                                            data-value={
                                                                  index + 27
                                                            }
                                                      >
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-number"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index +
                                                                        27
                                                                  }
                                                            >
                                                                  {index +
                                                                        1 +
                                                                        27}
                                                                  .
                                                            </div>
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-name"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index +
                                                                        27
                                                                  }
                                                            >
                                                                  {current.name}
                                                            </div>
                                                            {lessons &&
                                                            lessons[
                                                                  current
                                                                        .lessonId
                                                            ]?.testsAttempted >
                                                                  0 ? (
                                                                  <FontAwesomeIcon
                                                                        icon={
                                                                              faCircleCheck
                                                                        }
                                                                  />
                                                            ) : null}
                                                      </div>
                                                );
                                          })}
                              </div>
                        </div>

                        <div className={styles["challenges-sub-type"]}>
                              <h4 className={styles["sub-type-heading"]}>
                                    Special keys - 2
                              </h4>
                              <div
                                    className={styles["challenges"]}
                                    onClick={alphabetsOnClickHandler}
                              >
                                    {beginnerModeData
                                          .slice(40)
                                          .map((current, index) => {
                                                return (
                                                      <div
                                                            className={
                                                                  styles[
                                                                        "challenge"
                                                                  ] +
                                                                  " " +
                                                                  styles[
                                                                        "challenge-" +
                                                                              theme
                                                                  ]
                                                            }
                                                            data-value={
                                                                  index + 40
                                                            }
                                                      >
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-number"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index +
                                                                        40
                                                                  }
                                                            >
                                                                  {index +
                                                                        1 +
                                                                        40}
                                                                  .
                                                            </div>
                                                            <div
                                                                  className={
                                                                        styles[
                                                                              "lesson-name"
                                                                        ]
                                                                  }
                                                                  data-value={
                                                                        index +
                                                                        40
                                                                  }
                                                            >
                                                                  {current.name}
                                                            </div>
                                                            {lessons &&
                                                            lessons[
                                                                  current
                                                                        .lessonId
                                                            ]?.testsAttempted >
                                                                  0 ? (
                                                                  <FontAwesomeIcon
                                                                        icon={
                                                                              faCircleCheck
                                                                        }
                                                                  />
                                                            ) : null}
                                                      </div>
                                                );
                                          })}
                              </div>
                        </div>
                  </aside>

                  {showImageOverlay ? (
                        <ImageOverlay
                              index={0}
                              onclose={setShowImageOverlay}
                              images={[whichFingers]}
                        ></ImageOverlay>
                  ) : null}

                  {showVideoOverlay ? (
                        <ImageOverlay
                              index={0}
                              onclose={setShowVideoOverlay}
                              videos={[
                                    "https://res.cloudinary.com/diwrxz82u/video/upload/v1713037140/touch-typing-2.0/home-row-2_wwgjin.mp4",
                              ]}
                        ></ImageOverlay>
                  ) : null}
            </>
      );
};
