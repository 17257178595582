import styles from "./beginnerModeInformation.module.css";

import { beginnerModeData, keyToImageMapping } from "../../utilities/utilities";

export const BeginnerModeInformation = ({
      testStarted,
      settings,
      setShowLessons,
      currentKey,
      beginnerModeStats,
}) => {
      const currentFinger = keyToImageMapping[currentKey].hand;

      const isLeftShift = keyToImageMapping[currentKey]["leftShift"] === "true";
      const isRightShift =
            keyToImageMapping[currentKey]["rightShift"] === "true";

      const lessons = beginnerModeStats.lessons;
      console.log(lessons);
      let lesson = null;
      if (lessons) {
            lesson = lessons[beginnerModeData[settings.modeFour].lessonId];
      }
      return (
            <section className={styles["beginner-mode-information"]}>
                  <div
                        className={
                              styles["information-one"] +
                              " " +
                              styles["information-one-" + settings.theme]
                        }
                        style={testStarted ? { opacity: 0 } : null}
                  >
                        <div className={styles["word-title"]}>
                              {+settings.modeFour + 1}.{" "}
                              {beginnerModeData[settings.modeFour].name}
                              <button
                                    className={
                                          styles["last-ten-test-open-button"]
                                    }
                                    onClick={() => {
                                          setShowLessons(true);
                                    }}
                              >
                                    Lessons
                              </button>
                        </div>
                        <div className={styles["word-stats"]}>
                              <table className={styles["table"]}>
                                    <tbody>
                                          <tr>
                                                <th className={styles["th"]}>
                                                      highest speed
                                                </th>
                                                <th className={styles["th"]}>
                                                      total tests
                                                </th>
                                          </tr>
                                    </tbody>
                                    <tbody>
                                          <tr>
                                                <td className={styles["td"]}>
                                                      {lesson
                                                            ? lesson.highestSpeed
                                                            : 0}{" "}
                                                      wpm
                                                </td>

                                                <td className={styles["td"]}>
                                                      {lesson
                                                            ? lesson.testsAttempted
                                                            : 0}
                                                </td>
                                          </tr>
                                    </tbody>
                              </table>
                        </div>
                  </div>
                  <div
                        className={
                              styles["information-two"] +
                              " " +
                              styles["information-two-" + settings.theme]
                        }
                  >
                        <h2 className={styles["current-key"]}>{currentKey} </h2>
                        <div>
                              <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="70"
                                    viewBox="0 0 512 512"
                                    width="70"
                                    className={styles["hand"]}
                              >
                                    <title />
                                    <path
                                          d="M80,320V144a32,32,0,0,1,32-32h0a32,32,0,0,1,32,32V256"
                                          stroke="#000"
                                          fill={
                                                currentFinger ===
                                                      "leftHandPinkyFinger" ||
                                                isLeftShift
                                                      ? "black"
                                                      : "none"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="16"
                                    />
                                    <path
                                          d="M144,256V80a32,32,0,0,1,32-32h0a32,32,0,0,1,32,32V240"
                                          stroke="#000"
                                          fill={
                                                currentFinger ===
                                                "leftHandRingFinger"
                                                      ? "black"
                                                      : "none"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="16"
                                    />
                                    <path
                                          d="M208,240V48a32,32,0,0,1,32-32h0a32,32,0,0,1,32,32V240"
                                          stroke="#000"
                                          fill={
                                                currentFinger ===
                                                "leftHandMiddleFinger"
                                                      ? "black"
                                                      : "none"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="16"
                                    />
                                    <path
                                          d="M272,241V96a32,32,0,0,1,32-32h0a32,32,0,0,1,32,32V320"
                                          stroke="#000"
                                          fill={
                                                currentFinger ===
                                                "leftHandIndexFinger"
                                                      ? "black"
                                                      : "none"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="16"
                                    />

                                    <path
                                          d="M80,320c0,117.4,64,176,152,176s123.71-39.6,144-88l52.71-144c6.66-18.05,3.64-34.79-11.87-43.6h0c-15.52-8.82-35.91-4.28-44.31,11.68L336,320"
                                          stroke="#000"
                                          fill={
                                                currentFinger ===
                                                "leftHandThumbFinger"
                                                      ? "black"
                                                      : "none"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="16"
                                    />
                              </svg>
                              <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="70"
                                    viewBox="0 0 512 512"
                                    width="70"
                                    className={styles["hand"]}
                              >
                                    <title />
                                    <path
                                          d="M432,320V144a32,32,0,0,0-32-32h0a32,32,0,0,0-32,32V256"
                                          stroke="#000"
                                          fill={
                                                currentFinger ===
                                                      "rightHandPinkyFinger" ||
                                                isRightShift
                                                      ? "black"
                                                      : "none"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="16"
                                    />
                                    <path
                                          d="M368,256V80a32,32,0,0,0-32-32h0a32,32,0,0,0-32,32V240"
                                          stroke="#000"
                                          fill={
                                                currentFinger ===
                                                "rightHandRingFinger"
                                                      ? "black"
                                                      : "none"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="16"
                                    />
                                    <path
                                          d="M304,240V48a32,32,0,0,0-32-32h0a32,32,0,0,0-32,32V240"
                                          stroke="#000"
                                          fill={
                                                currentFinger ===
                                                "rightHandMiddleFinger"
                                                      ? "black"
                                                      : "none"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="16"
                                    />
                                    <path
                                          d="M240,241V96a32,32,0,0,0-32-32h0a32,32,0,0,0-32,32V320"
                                          stroke="#000"
                                          fill={
                                                currentFinger ===
                                                "rightHandIndexFinger"
                                                      ? "black"
                                                      : "none"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="16"
                                    />

                                    <path
                                          d="M432,320c0,117.4-64,176-152,176s-123.71-39.6-144-88L83.33,264c-6.66-18.05-3.64-34.79,11.87-43.6h0c15.52-8.82,35.91-4.28,44.31,11.68L176,320"
                                          stroke="#000"
                                          fill={
                                                currentFinger ===
                                                "rightHandThumbFinger"
                                                      ? "black"
                                                      : "none"
                                          }
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="16"
                                    />
                              </svg>

                              {/* <img
                                    width={"100px"}
                                    alt="hand"
                                    src={
                                          keyToImageMapping[currentKey]
                                                ?.hand === "right"
                                                ? leftHandPlain
                                                : keyToImageMapping[currentKey]
                                                        ?.img
                                    }
                                    className={styles["hand"]}
                              ></img>
                              <img
                                    width={"100px"}
                                    alt="hand"
                                    src={
                                          keyToImageMapping[currentKey]
                                                ?.hand === "left"
                                                ? rightHandPlain
                                                : keyToImageMapping[currentKey]
                                                        ?.img
                                    }
                                    className={styles["hand"]}
                              ></img> */}
                        </div>
                  </div>
            </section>
      );
};
