import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AwaitHomePage } from "./pages/homePage/awaitHomePage";
import { AwaitStatsPage } from "./pages/statsPage/awaitStatsPage";
import { AwaitGuidePage } from "./pages/guidePage/awaitGuidePage";
import {
      updateSettings,
      registerAction,
      loginAction,
      logoutUser,
      googleSignIn,
} from "./actions/actions";
import {
      statsPageLoader,
      homePageLoader,
      getWords,
      getUserPreviousSessionSettings,
      getUserStats,
      getUserStatsOne,
      getUserDetails,
      guidePageLoader,
      getBeginnerModeStats,
} from "./loaders/loaders";
import { RegisterPage } from "./pages/registerPage/registerPage";
import { LoginPage } from "./pages/loginPage/loginPage";
import { GoogleOAuthProvider } from "@react-oauth/google";

const router = createBrowserRouter([
      {
            path: "/",
            children: [
                  {
                        path: "/",
                        element: <AwaitHomePage></AwaitHomePage>,
                        loader: homePageLoader,
                        shouldRevalidate: () => {
                              return false;
                        },
                  },
                  {
                        path: "/statsPage",
                        element: <AwaitStatsPage></AwaitStatsPage>,
                        loader: statsPageLoader,
                        shouldRevalidate: () => {
                              return false;
                        },
                  },
                  {
                        path: "/guide",
                        element: <AwaitGuidePage></AwaitGuidePage>,
                        loader: guidePageLoader,
                        shouldRevalidate: () => {
                              return false;
                        },
                  },
                  {
                        path: "/stats",
                        loader: getUserStats,
                  },
                  {
                        path: "/statsOne",
                        loader: getUserStatsOne,
                  },
                  {
                        path: "/beginnerModeStats",
                        loader: getBeginnerModeStats,
                  },

                  {
                        path: "/settings",
                        action: updateSettings,
                  },
                  { path: "/userDetails", loader: getUserDetails },
                  {
                        path: "/previousSessionSettings",
                        loader: getUserPreviousSessionSettings,
                  },
                  {
                        path: "/register",
                        element: <RegisterPage></RegisterPage>,
                        action: registerAction,
                  },
                  {
                        path: "/login",
                        element: <LoginPage></LoginPage>,
                        action: loginAction,
                  },
                  {
                        path: "/login/googleAuth",
                        action: googleSignIn,
                  },
                  {
                        path: "/words",
                        loader: getWords,
                  },
                  {
                        path: "/logout",
                        action: logoutUser,
                  },
            ],
      },
]);

function App() {
      return (
            <GoogleOAuthProvider clientId="185613437255-12jifha5ab3gm97tcraumgt9ed0og4s0.apps.googleusercontent.com">
                  <RouterProvider router={router} />
            </GoogleOAuthProvider>
      );
}

export default App;
