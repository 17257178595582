import { defaultSettings, languagesAndWords } from "../utilities/utilities";
import {
      SettingsModel,
      StatsModel,
      BeginnerModeStatsModel,
} from "../models/models";

export const getUserLanguageSubTypeStats = async (request, response) => {
      try {
            let userStats = StatsModel.findOne({
                  language: request.language,
                  optionIndex: request.optionIndex,
            });

            if (userStats === null) {
                  userStats = {};
            }

            return {
                  status: "success",
                  payload: userStats,
            };
      } catch (error) {
            return {
                  status: "error",
                  message: error.message,
            };
      }
};

// getUserLanguageStats = async (request, response) => {
//     try {
//           const userStats = await statsModel.find({
//                 user: request.user._id,
//                 language: request.query.language || "english",
//           });

//           const languageInformation = await wordsModel.findOne({
//                 language: request.query.language || "english",
//           });

//           if (!languageInformation) {
//                 response.status(500).json({
//                       status: "error",
//                       message: "language does not exist",
//                 });
//           } else {
//                 let newArray = [];
//                 if (languageInformation.options.length > userStats.length) {
//                       for (
//                             let i = 0;
//                             i < languageInformation.options.length;
//                             i++
//                       ) {
//                             let found = false;
//                             for (let j = 0; j < userStats.length; j++) {
//                                   if (i === userStats[j]?.optionIndex) {
//                                         found = true;
//                                   }
//                             }
//                             if (!found) {
//                                   newArray.push({
//                                         subName: languageInformation.options[
//                                               i
//                                         ].name,
//                                         optionIndex: i,
//                                   });
//                             }
//                       }
//                 }

//                 response.status(200).json({
//                       status: "success",
//                       payload: [...userStats, ...newArray],
//                 });
//           }
//     } catch (error) {
//           response.status(500).json({
//                 status: "error",
//                 message: error.message,
//           });
//     }
// };

export const getUserSettings = async (request, response) => {
      try {
            let userSettings = SettingsModel.findOne();

            if (!userSettings) {
                  userSettings = defaultSettings;
            }

            return {
                  status: "success",
                  payload: userSettings,
            };
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const getBeginnerModeStats = async (request, response) => {
      try {
            let beginnerModeStats = BeginnerModeStatsModel.findOne();
            if (!beginnerModeStats) {
                  beginnerModeStats = {};
            }
            return {
                  status: "success",
                  payload: beginnerModeStats,
            };
      } catch (error) {
            return {
                  status: "error",
                  message: error.message,
            };
      }
};

export const getPreviousSessionSettings = async (request, response) => {
      try {
            let settings = SettingsModel.findOne();

            if (!settings) {
                  settings = defaultSettings;
            }

            const { language, optionIndex } = settings["language and range"];
            const languageDocument = languagesAndWords[language];

            const option = languageDocument.options[optionIndex];

            let stats = StatsModel.findOne({
                  language: language,
                  optionIndex: optionIndex,
            });

            let beginnerModeStats = BeginnerModeStatsModel.findOne();

            const totalResponse = await Promise.all([stats, beginnerModeStats]);

            [stats, beginnerModeStats] = totalResponse;

            const words = [];
            option.wordsSlices.forEach((slice) => {
                  for (let i = slice[0]; i <= slice[1]; i++) {
                        words.push(languageDocument.words[i]);
                  }
            });

            return {
                  status: "success",
                  payload: {
                        settings,
                        words,
                        stats: stats || {},
                        beginnerModeStats: beginnerModeStats || {},
                  },
            };
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const getUserLanguageStats = async (request, response) => {
      try {
            const userStats = StatsModel.find({
                  language: request.language || "english",
            });

            const languageInformation =
                  languagesAndWords[request.language || "english"];

            if (!languageInformation) {
                  return {
                        status: "error",
                        message: "language does not exist",
                  };
            } else {
                  let newArray = [];
                  if (languageInformation.options.length > userStats.length) {
                        for (
                              let i = 0;
                              i < languageInformation.options.length;
                              i++
                        ) {
                              let found = false;
                              for (let j = 0; j < userStats.length; j++) {
                                    if (i === userStats[j]?.optionIndex) {
                                          found = true;
                                    }
                              }
                              if (!found) {
                                    newArray.push({
                                          subName: languageInformation.options[
                                                i
                                          ].name,
                                          optionIndex: i,
                                    });
                              }
                        }
                  }

                  return {
                        status: "success",
                        payload: [...userStats, ...newArray],
                  };
            }
      } catch (error) {
            return {
                  status: "error",
                  message: error.message,
            };
      }
};

export const getLanguageSubTypeWords = async (request, response) => {
      try {
            let { language, optionIndex } = request;

            if (!language && !optionIndex) {
                  language = "english";
                  optionIndex = 0;
            }
            optionIndex = +optionIndex;

            // const document = await wordsModel.findOne({
            //       language,
            // });

            const languageData = languagesAndWords[language];

            if (languageData) {
                  let option = languageData.options.hasOwnProperty(optionIndex);

                  if (!option) {
                        throw new Error("cannot get words, wrong option index");
                  } else {
                        option = languageData.options[optionIndex];
                  }

                  const words = [];
                  option.wordsSlices.forEach((slice) => {
                        for (let i = slice[0]; i <= slice[1]; i++) {
                              words.push(languageData.words[i]);
                        }
                  });
                  return {
                        status: "success",
                        payload: words,
                  };
            } else {
                  throw new Error("laguage data does not exist");
            }
      } catch (error) {
            return { status: "error", message: error.message };
      }
};
