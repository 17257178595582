import { defer } from "react-router-dom";
import {
      getPreviousSessionSettings as a,
      getUserSettings as b,
      getUserLanguageSubTypeStats as c,
      getBeginnerModeStats as d,
      getUserLanguageStats as e,
      getLanguageSubTypeWords as f,
} from "./localStorageLoaders";

let backEndUrl = "http://localhost:8080/";
backEndUrl = "https://typing-web-app-zeta.vercel.app/";

export const getBeginnerModeStats = async () => {
      try {
            // const response = await fetch(`${backEndUrl}beginnerModeStats`, {
            //       credentials: "include",
            // });
            // const data = await response.json();
            const data = await d();
            if (data.status === "success") {
                  const newData = {
                        lessons: {},
                  };
                  data.payload.lessons?.forEach((element) => {
                        newData.lessons[element.lessonId] = element;
                  });

                  data.payload = newData;
            }
            console.log(data);
            return data;
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const getUserStats = async ({ request }) => {
      try {
            let url = new URL(request.url);
            let queryString = Array.from(url.searchParams).reduce(
                  (finalString, [key, value]) => {
                        if (finalString === "?") {
                              return finalString + key + "=" + value;
                        } else {
                              return finalString + "&" + key + "=" + value;
                        }
                  },
                  "?"
            );

            // const response = await fetch(
            //       `${backEndUrl}userStats${queryString}`,
            //       {
            //             credentials: "include",
            //       }
            // );
            // const data = await response.json();

            const data = c({
                  language: url.searchParams.get("language"),
                  optionIndex: +url.searchParams.get("optionIndex"),
            });

            return data;
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const getUserStatsOne = async ({ request }) => {
      try {
            let url = new URL(request.url);
            let queryString = Array.from(url.searchParams).reduce(
                  (finalString, [key, value]) => {
                        if (finalString === "?") {
                              return finalString + key + "=" + value;
                        } else {
                              return finalString + "&" + key + "=" + value;
                        }
                  },
                  "?"
            );

            // const response = await fetch(
            //       `${backEndUrl}userStatsOne${queryString}`,
            //       {
            //             credentials: "include",
            //       }
            // );
            // const data = await response.json();

            const data = await e({
                  language: url.searchParams.get("language"),
            });

            return data;
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const getUserDetails = async () => {
      try {
            // const response = await fetch(`${backEndUrl}userDetails`, {
            //       credentials: "include",
            // });
            // const data = await response.json();
            // return data;
            return { stats: "sucess", payload: { name: "guest" } };
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

const getUserSettings = async () => {
      try {
            // const response = await fetch(`${backEndUrl}settings`, {
            //       credentials: "include",
            // });
            // const data = await response.json();
            const data = await b();
            return data;
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const getUserPreviousSessionSettings = async () => {
      try {
            // const response = await fetch(
            //       `${backEndUrl}previousSessionSettings`,
            //       {
            //             credentials: "include",
            //       }
            // );
            // const data = await response.json();
            const data = await a();
            return data;
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const getWords = async ({ request }) => {
      try {
            let url = new URL(request.url);
            // let queryString = Array.from(url.searchParams).reduce(
            //       (finalString, [key, value]) => {
            //             if (finalString === "?") {
            //                   return finalString + key + "=" + value;
            //             } else {
            //                   return finalString + "&" + key + "=" + value;
            //             }
            //       },
            //       "?"
            // );

            // const response = await fetch(`${backEndUrl}words${queryString}`);
            // const data = await response.json();
            const data = await f({
                  language: url.searchParams.get("language"),
                  optionIndex: +url.searchParams.get("optionIndex"),
            });
            return data;
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const homePageLoader = async ({ request }) => {
      return defer({
            allLoaders: Promise.allSettled([
                  getUserDetails(),
                  getUserPreviousSessionSettings(),
            ]),
      });
};

export const statsPageLoader = async ({ request }) => {
      return defer({
            loaderData: Promise.allSettled([
                  getUserDetails(),
                  getUserStatsOne({ request }),
                  getUserSettings(),
            ]),
      });
};

export const guidePageLoader = async ({ request }) => {
      return defer({
            loader: Promise.allSettled([getUserDetails(), getUserSettings()]),
      });
};
