import styles from "./practiseWord.module.css";
export const PracticeWord = ({
      allWords,
      wordIndex,
      settings,
      statsData,
      classOne,
      serialNumber,
      setShowWordsQueue,
      setWordIndex,
      index,
      isWordsQueue,
      testStarted,
}) => {
      const practiseModeWordStats =
            statsData?.practiseMode?.wordsStats?.[allWords[wordIndex]];
      const testModeWordStats =
            statsData?.testMode?.wordsStats?.[allWords[wordIndex]];

      const lastTwentyTestsAverages = (wordStats) => {
            const [wpmSum, accuracySum] = wordStats.lastTwentyTests.reduce(
                  (total, current) => {
                        return [
                              total[0] + current.wpm,
                              total[1] + current.accuracy,
                        ];
                  },
                  [0, 0]
            );
            wordStats.lastTwentyTestsAverageWpm =
                  wpmSum / wordStats.lastTwentyTests.length;
            wordStats.lastTwentyTestsAverageAccuracy =
                  accuracySum / wordStats.lastTwentyTests.length;
      };
      if (practiseModeWordStats) {
            lastTwentyTestsAverages(practiseModeWordStats);
      }
      if (testModeWordStats) {
            lastTwentyTestsAverages(testModeWordStats);
      }

      const WordsQueueOpenButtonClickHandler = () => {
            setShowWordsQueue((previous) => {
                  return !previous;
            });
      };
      return (
            <div
                  className={
                        styles["word"] + " " + styles["word-" + settings.theme]
                  }
                  onClick={
                        setWordIndex
                              ? () => {
                                      setWordIndex(index);
                                      setShowWordsQueue(false);
                                }
                              : null
                  }
                  style={testStarted ? { opacity: 0 } : null}
            >
                  <h2 className={styles["word-title"]}>
                        {serialNumber}.{allWords[wordIndex]}
                  </h2>
                  <div className={styles["container"]}>
                        {isWordsQueue ? (
                              <button
                                    className={
                                          styles["words-queue-open-button"]
                                    }
                                    onClick={WordsQueueOpenButtonClickHandler}
                              >
                                    show queue
                              </button>
                        ) : null}
                  </div>

                  <div
                        className={
                              classOne
                                    ? styles["word-stats"] + " " + classOne
                                    : styles["word-stats"]
                        }
                  >
                        <table className={styles["table"]}>
                              <tr>
                                    <th className={styles["th"]} colspan="3">
                                          Practise mode
                                    </th>
                              </tr>
                              <tr>
                                    <th className={styles["th"]}></th>
                                    <th className={styles["th"]}>all tests</th>
                                    <th className={styles["th"]}>
                                          last 20 tests
                                    </th>
                              </tr>
                              <tr>
                                    <th className={styles["th"]}>speed</th>
                                    <td className={styles["td"]}>
                                          {practiseModeWordStats ? (
                                                <>
                                                      {Math.round(
                                                            practiseModeWordStats.averageWpm
                                                      )}{" "}
                                                      wpm
                                                </>
                                          ) : (
                                                "not attempted"
                                          )}
                                    </td>
                                    <td className={styles["td"]}>
                                          {practiseModeWordStats ? (
                                                <>
                                                      {Math.round(
                                                            practiseModeWordStats.lastTwentyTestsAverageWpm
                                                      )}{" "}
                                                      wpm
                                                </>
                                          ) : (
                                                "not attempted"
                                          )}
                                    </td>
                              </tr>
                              <tr>
                                    <th className={styles["th"]}>accuracy</th>
                                    <td className={styles["td"]}>
                                          {" "}
                                          {practiseModeWordStats ? (
                                                <>
                                                      {Math.round(
                                                            practiseModeWordStats.averageAccuracy
                                                      )}{" "}
                                                      %
                                                </>
                                          ) : (
                                                "not attempted"
                                          )}
                                    </td>
                                    <td className={styles["td"]}>
                                          {practiseModeWordStats ? (
                                                <>
                                                      {Math.round(
                                                            practiseModeWordStats.lastTwentyTestsAverageAccuracy
                                                      )}{" "}
                                                      %
                                                </>
                                          ) : (
                                                "not attempted"
                                          )}
                                    </td>
                              </tr>
                        </table>

                        <table className={styles["table"]}>
                              <tr>
                                    <th className={styles["th"]} colspan="3">
                                          Test mode
                                    </th>
                              </tr>
                              <tr>
                                    <th className={styles["th"]}></th>
                                    <th className={styles["th"]}>all tests</th>
                                    <th className={styles["th"]}>
                                          last 20 tests
                                    </th>
                              </tr>
                              <tr>
                                    <th className={styles["th"]}>speed</th>
                                    <td className={styles["td"]}>
                                          {testModeWordStats ? (
                                                <>
                                                      {Math.round(
                                                            testModeWordStats.averageWpm
                                                      )}{" "}
                                                      wpm
                                                </>
                                          ) : (
                                                "not attempted"
                                          )}
                                    </td>
                                    <td className={styles["td"]}>
                                          {testModeWordStats ? (
                                                <>
                                                      {Math.round(
                                                            testModeWordStats.lastTwentyTestsAverageWpm
                                                      )}{" "}
                                                      wpm
                                                </>
                                          ) : (
                                                "not attempted"
                                          )}
                                    </td>
                              </tr>
                              <tr>
                                    <th className={styles["th"]}>accuracy</th>
                                    <td className={styles["td"]}>
                                          {" "}
                                          {testModeWordStats ? (
                                                <>
                                                      {Math.round(
                                                            testModeWordStats.averageAccuracy
                                                      )}{" "}
                                                      %
                                                </>
                                          ) : (
                                                "not attempted"
                                          )}
                                    </td>
                                    <td className={styles["td"]}>
                                          {testModeWordStats ? (
                                                <>
                                                      {Math.round(
                                                            testModeWordStats.lastTwentyTestsAverageAccuracy
                                                      )}{" "}
                                                      %
                                                </>
                                          ) : (
                                                "not attempted"
                                          )}
                                    </td>
                              </tr>
                        </table>
                  </div>
            </div>
      );
};
