import styles from "./languageStats.module.css";
import { lastTwentyTestsAverages } from "../../utilities/utilities";
export const LanguageStats = ({ theme, statsData, classOne, timer }) => {
      let testMode;
      if (timer) {
            testMode = statsData?.testMode?.timerBasedStats[timer];
      } else {
            testMode = statsData?.testMode;
      }

      if (testMode) {
            [
                  testMode.lastTwentyTestsAverageWpm,
                  testMode.lastTwentyTestsAverageAccuracy,
            ] = lastTwentyTestsAverages(testMode.lastTwentyTests);
      }

      return (
            <div className={styles["word"] + " " + styles["word-" + theme]}>
                  <h4 className={styles["word-title"]}>
                        {timer ? timer + "s" : "Overall"}
                  </h4>
                  <div
                        className={
                              classOne
                                    ? styles["word-stats"] + " " + classOne
                                    : styles["word-stats"]
                        }
                  >
                        <table className={styles["table"]}>
                              <tbody>
                                    <tr>
                                          <th className={styles["th"]}>
                                                all tests averages
                                          </th>
                                          {/* <th>all tests avg accuracy</th> */}
                                          <th className={styles["th"]}>
                                                last 20 tests averages
                                          </th>
                                          {/* <th>last 20 tests avg accuracy</th> */}
                                          <th className={styles["th"]}>
                                                highest speed of a test
                                          </th>
                                          <th className={styles["th"]}>
                                                total tests
                                          </th>
                                    </tr>
                              </tbody>
                              <tbody>
                                    <tr>
                                          <td className={styles["td"]}>
                                                {testMode ? (
                                                      <>
                                                            {Math.round(
                                                                  testMode.averageWpm
                                                            )}{" "}
                                                            wpm /{" "}
                                                            {Math.round(
                                                                  testMode.averageAccuracy
                                                            )}{" "}
                                                            %
                                                      </>
                                                ) : (
                                                      "not attempted"
                                                )}
                                          </td>

                                          <td className={styles["td"]}>
                                                {testMode ? (
                                                      <>
                                                            {Math.round(
                                                                  testMode.lastTwentyTestsAverageWpm
                                                            )}{" "}
                                                            wpm /{" "}
                                                            {Math.round(
                                                                  testMode.lastTwentyTestsAverageAccuracy
                                                            )}{" "}
                                                            %
                                                      </>
                                                ) : (
                                                      "not attempted"
                                                )}
                                          </td>

                                          <td className={styles["td"]}>
                                                {testMode ? (
                                                      <>
                                                            {Math.round(
                                                                  testMode
                                                                        .highestWpmOfATest
                                                                        .wpm
                                                            )}{" "}
                                                            wpm
                                                      </>
                                                ) : (
                                                      "not attempted"
                                                )}
                                          </td>
                                          <td className={styles["td"]}>
                                                {testMode ? (
                                                      <>
                                                            {Math.round(
                                                                  testMode.totalNumberOfFinishedTests
                                                            )}
                                                      </>
                                                ) : (
                                                      "not attempted"
                                                )}
                                          </td>
                                    </tr>
                              </tbody>
                        </table>
                  </div>
            </div>
      );
};
