import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { colorRingOptions } from "../../utilities/utilities";
import "react-toastify/dist/ReactToastify.css";
import { HomePage } from "./homePage";
import styles from "./homePage.module.css";

export const AwaitHomePage = () => {
      const { allLoaders } = useLoaderData();

      return (
            <Suspense
                  fallback={
                        <div className={styles["page"]}>
                              <ColorRing {...colorRingOptions} />
                              <div className={styles["loading-text"]}>
                                    please wait, loading some data that is very
                                    crucial to run this
                              </div>
                        </div>
                  }
            >
                  <Await resolve={allLoaders}>
                        <HomePage></HomePage>
                  </Await>
            </Suspense>
      );
};
