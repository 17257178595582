import { useAsyncValue, useFetcher } from "react-router-dom";
import { useState } from "react";
import { NavBar } from "../../components/navBar/navBar";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
      toastOptions,
      lastTwentyTestsAverages,
      highestAverageSpeedOfAWord,
      highestAverageAcuuracyOfAWord,
} from "../../utilities/utilities";
import { ColorRing } from "react-loader-spinner";
import { colorRingOptions } from "../../utilities/utilities";
import { WordsBarGraph } from "./wordsBarGraph";
import { LanguageStats } from "./languageStats";
import { ErrorPage } from "../../components/error/errorPage";
import { CardOne } from "../../components/cards/cardOne";
import { CardTwo } from "../../components/cards/cardTwo";
import styles from "./statsPage.module.css";

export const StatsPage = () => {
      const userDetailsLoaderData = useAsyncValue()[0].value;
      const [loaderTwoData, setLoaderTwoData] = useState(
            useAsyncValue()[1].value
      );
      const [settingsData] = useState(useAsyncValue()[2].value);
      const [showLoader, setShowLoader] = useState(false);
      const [lastTenTestsIndex, setLastTenTestsIndex] = useState(0);
      const [language, setLanguage] = useState({
            current: "english",
            previous: "english",
      });

      const languageStatsFetcherOne = useFetcher();
      const languageStatsFetcherOneStatus =
            languageStatsFetcherOne.data &&
            languageStatsFetcherOne.state === "idle";

      useEffect(() => {
            if (languageStatsFetcherOneStatus) {
                  const data = languageStatsFetcherOne.data;
                  if (data.status === "success") {
                        setLoaderTwoData(data);
                        setLastTenTestsIndex(0);
                        setLanguage((previous) => {
                              return {
                                    ...previous,
                                    previous: previous.current,
                              };
                        });
                  } else {
                        toast.error(data.message, toastOptions);
                        setLanguage((previous) => {
                              return {
                                    ...previous,
                                    current: previous.previous,
                              };
                        });
                  }
                  setShowLoader(false);
            } else if (languageStatsFetcherOne.state !== "idle") {
                  setShowLoader(true);
            }
      }, [languageStatsFetcherOne]);

      const languageFilterHandlerOne = (event) => {
            const value = event.target.value;
            setLanguage((previous) => {
                  return { ...previous, current: value };
            });
            languageStatsFetcherOne.submit(
                  { language: value },
                  {
                        method: "GET",
                        action: "/statsOne",
                  }
            );
      };

      const languageFilterHandler = (event) => {
            const value = event.target.value;
            setLastTenTestsIndex(+value);
      };

      let showError = false;
      let error = null;

      if (
            settingsData.status === "error" ||
            loaderTwoData.status === "error" ||
            userDetailsLoaderData.status === "error"
      ) {
            showError = true;
      }
      if (settingsData.status === "error") {
            error = settingsData;
      } else if (loaderTwoData.status === "error") {
            error = loaderTwoData;
      } else if (userDetailsLoaderData.status === "error") {
            error = userDetailsLoaderData;
      }

      if (showError) {
            return <ErrorPage error={error}></ErrorPage>;
      }

      return (
            <>
                  <CardOne
                        attributes={{ className: styles["page"] }}
                        theme={settingsData.payload.theme}
                  >
                        <NavBar
                              theme={settingsData.payload.theme}
                              userDetails={userDetailsLoaderData.payload}
                        ></NavBar>
                        <ToastContainer></ToastContainer>

                        <main className={styles["main"]}>
                              {showLoader ? (
                                    <div className={styles["loader"]}>
                                          <ColorRing {...colorRingOptions} />
                                    </div>
                              ) : null}
                              <section>
                                    <CardTwo
                                          attributes={{
                                                className:
                                                      styles[
                                                            "language-filter-section"
                                                      ],
                                          }}
                                          theme={settingsData.payload.theme}
                                    >
                                          <select
                                                className={
                                                      styles["language-filter"]
                                                }
                                                onChange={
                                                      languageFilterHandlerOne
                                                }
                                                value={language.current}
                                          >
                                                <option disabled>
                                                      languages and ranges
                                                </option>
                                                <option value="english">
                                                      english
                                                </option>
                                                <option value="javascript">
                                                      javascript
                                                </option>
                                          </select>
                                    </CardTwo>
                              </section>
                              <section>
                                    <CardTwo theme={settingsData.payload.theme}>
                                          <table
                                                className={
                                                      styles[
                                                            "language-overall-stats-table"
                                                      ]
                                                }
                                          >
                                                <tr>
                                                      <th
                                                            className={
                                                                  styles[
                                                                        "language-overall-stats-table-head"
                                                                  ] +
                                                                  " " +
                                                                  styles["th"]
                                                            }
                                                      >
                                                            sub-type
                                                      </th>
                                                      <th
                                                            className={
                                                                  styles[
                                                                        "language-overall-stats-table-head"
                                                                  ] +
                                                                  " " +
                                                                  styles["th"]
                                                            }
                                                      >
                                                            all tests average
                                                      </th>
                                                      <th
                                                            className={
                                                                  styles[
                                                                        "language-overall-stats-table-head"
                                                                  ] +
                                                                  " " +
                                                                  styles["th"]
                                                            }
                                                      >
                                                            last 20 tests
                                                            average
                                                            speed/accuracy
                                                      </th>
                                                      <th
                                                            className={
                                                                  styles[
                                                                        "language-overall-stats-table-head"
                                                                  ] +
                                                                  " " +
                                                                  styles["th"]
                                                            }
                                                      >
                                                            highest speed in a
                                                            test
                                                      </th>
                                                      <th
                                                            className={
                                                                  styles[
                                                                        "language-overall-stats-table-head"
                                                                  ] +
                                                                  " " +
                                                                  styles["th"]
                                                            }
                                                      >
                                                            total tests
                                                      </th>
                                                </tr>

                                                {loaderTwoData.payload
                                                      .sort((a, b) => {
                                                            return (
                                                                  a.optionIndex -
                                                                  b.optionIndex
                                                            );
                                                      })
                                                      .map((subTypeStats) => {
                                                            if (
                                                                  subTypeStats.testMode
                                                            ) {
                                                                  [
                                                                        subTypeStats
                                                                              .testMode
                                                                              .lastTwentyTestsAverageWpm,
                                                                        subTypeStats
                                                                              .testMode
                                                                              .lastTwentyTestsAverageAccuracy,
                                                                  ] =
                                                                        lastTwentyTestsAverages(
                                                                              subTypeStats
                                                                                    .testMode
                                                                                    .lastTwentyTests
                                                                        );
                                                                  highestAverageSpeedOfAWord(
                                                                        subTypeStats
                                                                  );
                                                                  highestAverageAcuuracyOfAWord(
                                                                        subTypeStats
                                                                  );
                                                            }
                                                            return (
                                                                  <tr>
                                                                        <th
                                                                              className={
                                                                                    styles[
                                                                                          "language-overall-stats-table-head"
                                                                                    ] +
                                                                                    " " +
                                                                                    styles[
                                                                                          "th"
                                                                                    ]
                                                                              }
                                                                        >
                                                                              {subTypeStats.subName
                                                                                    ? subTypeStats.subName.replace(
                                                                                            "english",
                                                                                            ""
                                                                                      )
                                                                                    : "-"}
                                                                        </th>
                                                                        <td
                                                                              className={
                                                                                    styles[
                                                                                          "language-overall-stats-table-data"
                                                                                    ] +
                                                                                    " " +
                                                                                    styles[
                                                                                          "td"
                                                                                    ]
                                                                              }
                                                                        >
                                                                              {subTypeStats.testMode
                                                                                    ? `${Math.round(
                                                                                            subTypeStats
                                                                                                  .testMode
                                                                                                  .averageWpm
                                                                                      )}wpm /
                                                                          ${Math.round(
                                                                                subTypeStats
                                                                                      .testMode
                                                                                      .averageAccuracy
                                                                          )}%`
                                                                                    : "-"}
                                                                        </td>
                                                                        <td
                                                                              className={
                                                                                    styles[
                                                                                          "language-overall-stats-table-data"
                                                                                    ] +
                                                                                    " " +
                                                                                    styles[
                                                                                          "td"
                                                                                    ]
                                                                              }
                                                                        >
                                                                              {subTypeStats.testMode
                                                                                    ? `${Math.round(
                                                                                            subTypeStats
                                                                                                  .testMode
                                                                                                  .lastTwentyTestsAverageWpm
                                                                                      )}wpm /
                                                                          ${Math.round(
                                                                                subTypeStats
                                                                                      .testMode
                                                                                      .lastTwentyTestsAverageAccuracy
                                                                          )}%`
                                                                                    : "-"}
                                                                        </td>
                                                                        <td
                                                                              className={
                                                                                    styles[
                                                                                          "language-overall-stats-table-data"
                                                                                    ] +
                                                                                    " " +
                                                                                    styles[
                                                                                          "td"
                                                                                    ]
                                                                              }
                                                                        >
                                                                              {subTypeStats.testMode
                                                                                    ? `${subTypeStats.testMode.highestWpmOfATest.wpm}wpm`
                                                                                    : "-"}
                                                                        </td>
                                                                        <td
                                                                              className={
                                                                                    styles[
                                                                                          "language-overall-stats-table-data"
                                                                                    ] +
                                                                                    " " +
                                                                                    styles[
                                                                                          "td"
                                                                                    ]
                                                                              }
                                                                        >
                                                                              {subTypeStats.testMode
                                                                                    ? subTypeStats
                                                                                            .testMode
                                                                                            .totalNumberOfFinishedTests
                                                                                    : "-"}
                                                                        </td>
                                                                  </tr>
                                                            );
                                                      })}
                                          </table>
                                    </CardTwo>
                              </section>

                              <section>
                                    <CardTwo
                                          attributes={{
                                                className:
                                                      styles[
                                                            "language-filter-section"
                                                      ],
                                          }}
                                          theme={settingsData.payload.theme}
                                    >
                                          <select
                                                className={
                                                      styles["language-filter"]
                                                }
                                                onChange={languageFilterHandler}
                                                value={lastTenTestsIndex}
                                          >
                                                <option disabled>
                                                      languages and ranges
                                                </option>
                                                {loaderTwoData.payload.map(
                                                      (subType) => {
                                                            return (
                                                                  <option
                                                                        value={
                                                                              subType.optionIndex
                                                                        }
                                                                  >
                                                                        {
                                                                              subType.subName
                                                                        }
                                                                  </option>
                                                            );
                                                      }
                                                )}
                                          </select>
                                    </CardTwo>
                              </section>

                              <section
                                    className={
                                          styles["language-sub-type-stats"]
                                    }
                              >
                                    <LanguageStats
                                          statsData={
                                                loaderTwoData.payload[
                                                      lastTenTestsIndex
                                                ]
                                          }
                                          theme={settingsData.payload.theme}
                                    ></LanguageStats>
                                    <LanguageStats
                                          statsData={
                                                loaderTwoData.payload[
                                                      lastTenTestsIndex
                                                ]
                                          }
                                          timer={15}
                                          theme={settingsData.payload.theme}
                                    ></LanguageStats>
                                    <LanguageStats
                                          statsData={
                                                loaderTwoData.payload[
                                                      lastTenTestsIndex
                                                ]
                                          }
                                          timer={30}
                                          theme={settingsData.payload.theme}
                                    ></LanguageStats>
                                    <LanguageStats
                                          statsData={
                                                loaderTwoData.payload[
                                                      lastTenTestsIndex
                                                ]
                                          }
                                          timer={60}
                                          theme={settingsData.payload.theme}
                                    ></LanguageStats>
                              </section>
                              <section
                                    className={styles["last-ten-tests-section"]}
                              >
                                    <h3 style={{ color: "black" }}>
                                          Last 20 tests
                                    </h3>
                                    <CardTwo theme={settingsData.payload.theme}>
                                          <table
                                                className={
                                                      styles[
                                                            "last-ten-tests-table"
                                                      ] +
                                                      " " +
                                                      styles[
                                                            "last-ten-tests-table-" +
                                                                  settingsData
                                                                        .payload
                                                                        .theme
                                                      ]
                                                }
                                          >
                                                <tbody>
                                                      <tr>
                                                            <th
                                                                  className={
                                                                        styles[
                                                                              "th"
                                                                        ]
                                                                  }
                                                            >
                                                                  s.no
                                                            </th>
                                                            <th
                                                                  className={
                                                                        styles[
                                                                              "th"
                                                                        ]
                                                                  }
                                                            >
                                                                  speed
                                                            </th>
                                                            <th
                                                                  className={
                                                                        styles[
                                                                              "th"
                                                                        ]
                                                                  }
                                                            >
                                                                  Accuracy
                                                            </th>
                                                            <th
                                                                  className={
                                                                        styles[
                                                                              "th"
                                                                        ]
                                                                  }
                                                            >
                                                                  Time
                                                            </th>
                                                            <th
                                                                  className={
                                                                        styles[
                                                                              "th"
                                                                        ]
                                                                  }
                                                            >
                                                                  Date(mm-dd-yyyy)
                                                            </th>
                                                      </tr>
                                                </tbody>
                                                {(() => {
                                                      let newArray = [];

                                                      for (
                                                            let i = 0;
                                                            i < 20;
                                                            i++
                                                      ) {
                                                            if (
                                                                  loaderTwoData.payload.sort(
                                                                        (
                                                                              a,
                                                                              b
                                                                        ) => {
                                                                              return (
                                                                                    a.optionIndex -
                                                                                    b.optionIndex
                                                                              );
                                                                        }
                                                                  )[
                                                                        lastTenTestsIndex
                                                                  ]?.testMode
                                                                        ?.lastTwentyTests[
                                                                        i
                                                                  ]
                                                            ) {
                                                                  const reversedArray =
                                                                        loaderTwoData.payload[
                                                                              lastTenTestsIndex
                                                                        ].testMode.lastTwentyTests.toReversed();
                                                                  const test =
                                                                        reversedArray[
                                                                              i
                                                                        ];
                                                                  newArray.push(
                                                                        <tbody>
                                                                              <tr>
                                                                                    <td
                                                                                          className={
                                                                                                styles[
                                                                                                      "td"
                                                                                                ]
                                                                                          }
                                                                                    >
                                                                                          {i +
                                                                                                1}
                                                                                    </td>
                                                                                    <td
                                                                                          className={
                                                                                                styles[
                                                                                                      "td"
                                                                                                ]
                                                                                          }
                                                                                    >
                                                                                          {
                                                                                                test.wpm
                                                                                          }{" "}
                                                                                          wpm
                                                                                    </td>
                                                                                    <td
                                                                                          className={
                                                                                                styles[
                                                                                                      "td"
                                                                                                ]
                                                                                          }
                                                                                    >
                                                                                          {
                                                                                                test.accuracy
                                                                                          }{" "}
                                                                                          %
                                                                                    </td>
                                                                                    <td
                                                                                          className={
                                                                                                styles[
                                                                                                      "td"
                                                                                                ]
                                                                                          }
                                                                                    >
                                                                                          {
                                                                                                test.timer
                                                                                          }{" "}
                                                                                          s
                                                                                    </td>
                                                                                    <td
                                                                                          className={
                                                                                                styles[
                                                                                                      "td"
                                                                                                ]
                                                                                          }
                                                                                    >
                                                                                          {(() => {
                                                                                                let date =
                                                                                                      new Date(
                                                                                                            test.date
                                                                                                      );
                                                                                                return date.toLocaleString();
                                                                                          })()}
                                                                                    </td>
                                                                              </tr>
                                                                        </tbody>
                                                                  );
                                                            } else {
                                                                  newArray.push(
                                                                        <tbody>
                                                                              <tr>
                                                                                    <td
                                                                                          className={
                                                                                                styles[
                                                                                                      "td"
                                                                                                ]
                                                                                          }
                                                                                    >
                                                                                          {i +
                                                                                                1}
                                                                                    </td>
                                                                                    <td
                                                                                          className={
                                                                                                styles[
                                                                                                      "td"
                                                                                                ]
                                                                                          }
                                                                                    >
                                                                                          -
                                                                                    </td>
                                                                                    <td
                                                                                          className={
                                                                                                styles[
                                                                                                      "td"
                                                                                                ]
                                                                                          }
                                                                                    >
                                                                                          -
                                                                                    </td>
                                                                                    <td
                                                                                          className={
                                                                                                styles[
                                                                                                      "td"
                                                                                                ]
                                                                                          }
                                                                                    >
                                                                                          -
                                                                                    </td>
                                                                                    <td
                                                                                          className={
                                                                                                styles[
                                                                                                      "td"
                                                                                                ]
                                                                                          }
                                                                                    >
                                                                                          -
                                                                                    </td>
                                                                              </tr>
                                                                        </tbody>
                                                                  );
                                                            }
                                                      }
                                                      return newArray;
                                                })()}
                                          </table>
                                    </CardTwo>
                              </section>

                              <section
                                    className={
                                          styles["characters-bar-graph-section"]
                                    }
                              >
                                    <WordsBarGraph
                                          loaderData={loaderTwoData}
                                          lastTenTestsIndex={lastTenTestsIndex}
                                          theme={settingsData.payload.theme}
                                    ></WordsBarGraph>
                              </section>
                        </main>
                  </CardOne>
            </>
      );
};
