import styles from "./cardTwo.module.css";
export const CardTwo = ({ children, attributes, theme }) => {
      if (!attributes) {
            attributes = {};
      }
      if (attributes.className) {
            attributes.className += " " + styles["card-two-" + theme];
      } else {
            attributes.className = styles["card-two-" + theme];
      }
      return <div {...attributes}>{children}</div>;
};
