export class SettingsModel {
      timer = 15;
      theme = "theme-1";
      sound = "soundA";
      ["language and range"] = {
            language: "english",
            fullName: "english (1-100)",
            optionIndex: 0,
      };
      mode = "test";
      modeOne = "words";
      modeTwo = "0";
      modeThree = 1000;
      modeFour = 0;
      textEffect = "text-effect-3";
      constructor(input) {
            console.log(input);
            let inputEntries = Object.entries(input);
            for (let [key, value] of inputEntries) {
                  this[key] = value;
            }
      }

      static findOne(query) {
            try {
                  let settings = localStorage.getItem("settings");
                  if (settings === null) {
                        return null;
                  } else {
                        settings = JSON.parse(settings);
                        // let documentToFind = null;
                        // settings.forEach((document) => {
                        //       if (document.user === query.user) {
                        //             documentToFind = document;
                        //       }
                        // });

                        return settings;
                  }
            } catch (error) {
                  throw new Error("something went wrong");
            }
      }
      static save(document) {
            localStorage.setItem("settings", JSON.stringify(document));
      }
}

export class StatsModel {
      language;
      optionIndex;
      subName;
      testMode = {
            firstTest: Date,
            lastTest: Date,
            totalNumberOfStartedTests: 0,
            totalNumberOfFinishedTests: 0,
            averageWpm: 0,
            averageAccuracy: 0,
            lastTwentyTests: [],
            wordsStats: {},
            speedDistribution: {},
            highestWpmOfATest: {
                  wpm: 0,
                  accuracy: 0,
                  date: Date,
            },

            timerBasedStats: {
                  15: {
                        averageWpm: 0,
                        averageAccuracy: 0,
                        totalNumberOfFinishedTests: 0,
                        highestWpmOfATest: {
                              wpm: 0,
                              accuracy: 0,
                              date: Date,
                        },
                        lastTwentyTests: [],
                  },
                  30: {
                        averageWpm: 0,
                        averageAccuracy: 0,
                        totalNumberOfFinishedTests: 0,
                        highestWpmOfATest: {
                              wpm: 0,
                              accuracy: 0,
                              date: Date,
                        },
                        lastTwentyTests: [],
                  },
                  60: {
                        averageWpm: 0,
                        averageAccuracy: 0,
                        totalNumberOfFinishedTests: 0,
                        highestWpmOfATest: {
                              wpm: 0,
                              accuracy: 0,
                              date: Date,
                        },
                        lastTwentyTests: [],
                  },
            },
      };
      practiseMode = {
            wordsStats: {},
      };
      constructor(input) {
            this.language = input.language;
            this.optionIndex = input.optionIndex;
            this.subName = input.subName;
      }
      static findOne(query) {
            try {
                  let stats = localStorage.getItem("languagesStats");
                  if (stats === null) {
                        return null;
                  } else {
                        stats = JSON.parse(stats);
                        let documentToFind = null;

                        stats.forEach((document) => {
                              console.log(document);
                              if (
                                    document.language === query.language &&
                                    document.optionIndex === query.optionIndex
                              ) {
                                    documentToFind = document;
                              }
                        });

                        return documentToFind;
                  }
            } catch (error) {
                  throw new Error("something went wrong");
            }
      }

      static find({ language }) {
            try {
                  let stats = localStorage.getItem("languagesStats");
                  if (stats === null) {
                        return [];
                  } else {
                        stats = JSON.parse(stats);
                        let documentToFind = [];

                        stats.forEach((document) => {
                              if (document.language === language) {
                                    documentToFind.push(document);
                              }
                        });

                        return documentToFind;
                  }
            } catch (error) {
                  throw new Error("something went wrong");
            }
      }
      static save(document) {
            let stats = JSON.parse(localStorage.getItem("languagesStats"));
            if (!stats) {
                  stats = [document];
            } else {
                  let found = false;
                  for (let i = 0; i < stats.length; i++) {
                        if (
                              stats[i].language === document.language &&
                              stats[i].optionIndex === document.optionIndex
                        ) {
                              stats[i] = document;
                              found = true;
                        }
                  }
                  if (!found) {
                        stats.push(document);
                  }
            }
            localStorage.setItem("languagesStats", JSON.stringify(stats));
      }
}

export class BeginnerModeStatsModel {
      lessons;
      constructor(input) {
            this.lessons = input.lessons;
      }

      static findOne() {
            const beginnerModeStats = JSON.parse(
                  localStorage.getItem("beginnerModeStats")
            );
            return beginnerModeStats;
      }
      static save(document) {
            localStorage.setItem("beginnerModeStats", JSON.stringify(document));
      }
}
