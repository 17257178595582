import { Link, Form, useNavigate } from "react-router-dom";
import styles from "./navBar.module.css";
import { useContext, useState } from "react";
import { authContext } from "../../context/auth";
import { UserInformation } from "./userInformation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
      faUser,
      faHome,
      faChartSimple,
      faCircleInfo,
      faFile,
} from "@fortawesome/free-solid-svg-icons";
// import { GoogleAuth } from "../../pages/loginPage/googleAuth";
import { NavItem } from "./navItem";
import { ExportImport } from "./exportImport";
export const NavBar = ({ theme, userDetails }) => {
      const [showUserInfromation, setShowUserInformation] = useState(false);
      const [showExportImport, setShowExportImport] = useState(false);

      const accountClickHandler = () => {
            setShowUserInformation((previous) => {
                  return !previous;
            });
      };
      return (
            <>
                  <nav className={styles["nav-bar"]}>
                        <h2 className={styles.logo}>MINITYPE</h2>

                        <div className={styles["nav-items"]}>
                              <NavItem text="export/import" theme={theme}>
                                    <FontAwesomeIcon
                                          icon={faFile}
                                          className={styles["nav-link"]}
                                          onClick={() => {
                                                setShowExportImport(true);
                                          }}
                                    />
                              </NavItem>
                              <NavItem text="home" theme={theme}>
                                    <Link
                                          to="/"
                                          className={styles["nav-link"]}
                                          aria-label="home page"
                                    >
                                          <FontAwesomeIcon
                                                icon={faHome}
                                          ></FontAwesomeIcon>
                                    </Link>
                              </NavItem>
                              <NavItem text="guide" theme={theme}>
                                    <Link
                                          to={"/guide"}
                                          className={styles["nav-link"]}
                                          aria-label="guide page"
                                    >
                                          <FontAwesomeIcon
                                                icon={faCircleInfo}
                                          />
                                    </Link>
                              </NavItem>
                              <NavItem text="stats" theme={theme}>
                                    <Link
                                          className={
                                                styles["cart-link"] +
                                                " " +
                                                styles["nav-link"]
                                          }
                                          to={"/statsPage"}
                                          aria-label="stats page"
                                    >
                                          <FontAwesomeIcon
                                                icon={faChartSimple}
                                          />
                                    </Link>
                              </NavItem>

                              {/* {!userDetails ? <GoogleAuth></GoogleAuth> : null} */}
                              {/* {userDetails ? (
                                    <NavItem text="account" theme={theme}>
                                          <button
                                                onClick={accountClickHandler}
                                                className={
                                                      styles["logout-button"] +
                                                      " " +
                                                      styles["nav-link"]
                                                }
                                          >
                                                <FontAwesomeIcon
                                                      icon={faUser}
                                                ></FontAwesomeIcon>
                                          </button>
                                    </NavItem>
                              ) : null} */}
                        </div>
                        {/* {showUserInfromation ? (
                              <UserInformation
                                    setShowUserInformation={
                                          setShowUserInformation
                                    }
                                    theme={theme}
                                    userDetails={userDetails}
                              ></UserInformation>
                        ) : null} */}
                  </nav>

                  {showExportImport ? (
                        <ExportImport
                              setShowExportImport={setShowExportImport}
                              theme={theme}
                        ></ExportImport>
                  ) : null}
            </>
      );
};
