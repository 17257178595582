import { NavBar } from "../navBar/navBar";
import styles from "./error.module.css";
import { useRouteError } from "react-router-dom";
export const ErrorPage = ({ error }) => {
      return (
            <div className={styles["page"]}>
                  <NavBar></NavBar>
                  <main className={styles["main"]}>
                        <h2>
                              {error.message === "no token"
                                    ? "please login"
                                    : error.message}
                        </h2>
                  </main>
            </div>
      );
};
