import { NavBar } from "../../components/navBar/navBar";
import { useAsyncValue } from "react-router-dom";
import { ErrorPage } from "../../components/error/errorPage";
import { defaultSettings } from "../../utilities/utilities";
import { CardOne } from "../../components/cards/cardOne";
import styles from "./guidePage.module.css";
const theme = "theme-2";
export const GuidePage = () => {
      const userDetailsLoaderData = useAsyncValue()[0].value;
      const settingsLoaderData = useAsyncValue()[1].value;
      const settings =
            settingsLoaderData.status === "error"
                  ? defaultSettings
                  : settingsLoaderData.payload;
      let showError = false;
      let error = null;
      if (
            userDetailsLoaderData.status === "error" &&
            userDetailsLoaderData.message !== "no token"
      ) {
            showError = true;
            if (userDetailsLoaderData.message === "jwt expired") {
                  error = {
                        message: "session expired,please login again",
                  };
            } else {
                  error = { message: userDetailsLoaderData.message };
            }
      }
      if (
            settingsLoaderData.status === "error" &&
            settingsLoaderData.message !== "no token"
      ) {
            error = settingsLoaderData;
            showError = true;
      }

      if (showError) {
            return <ErrorPage error={error}></ErrorPage>;
      }

      return (
            <CardOne
                  attributes={{ className: styles["page"] }}
                  theme={settings.theme}
            >
                  <NavBar
                        theme={settings.theme}
                        userDetails={userDetailsLoaderData.payload}
                  ></NavBar>

                  <main className={styles["main"]}>
                        <h1 className={styles["guide-heading"]}>Guide</h1>
                        <h3
                              className={
                                    styles["heading"] +
                                    " " +
                                    styles["heading-" + settings.theme]
                              }
                        >
                              Note
                        </h3>
                        <p
                              className={
                                    styles["introduction"] +
                                    " " +
                                    styles["content"] +
                                    " " +
                                    styles["content-" + settings.theme] +
                                    " " +
                                    styles["p"]
                              }
                        >
                              The app currently is in experimental and testing
                              statge.
                        </p>
                        <h3
                              className={
                                    styles["heading"] +
                                    " " +
                                    styles["heading-" + settings.theme]
                              }
                        >
                              Introduction
                        </h3>
                        <p
                              className={
                                    styles["introduction"] +
                                    " " +
                                    styles["content"] +
                                    " " +
                                    styles["content-" + settings.theme] +
                                    " " +
                                    styles["p"]
                              }
                        >
                              A web based touch typing. Experience
                              simple,elegant,fast, clean touch typing and
                              improve your typing skills
                        </p>

                        <div>
                              <h3
                                    className={
                                          styles["heading"] +
                                          " " +
                                          styles["heading-" + settings.theme]
                                    }
                              >
                                    Settings explained
                              </h3>
                              <div
                                    className={
                                          styles["content"] +
                                          " " +
                                          styles["content-" + settings.theme]
                                    }
                              >
                                    <ul className={styles["ul"]}>
                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      Timers:{" "}
                                                </b>
                                                <br></br>
                                                choose timers according to your
                                                choice. Having different timers
                                                let you practise in more
                                                effective way.
                                          </li>
                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      Themes:{" "}
                                                </b>
                                                <br></br>
                                                multiple themes to choose from.
                                                Choose the one that you like the
                                                most. Having different themes
                                                can enrich your typing
                                                experience.
                                          </li>
                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      sounds:{" "}
                                                </b>
                                                <br></br>
                                                multiple sounds to choose from.
                                                Choose the one that you like the
                                                most. Having different sound can
                                                enrich your typing experience.
                                          </li>

                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      Mode one:{" "}
                                                </b>
                                                <br></br>
                                                <ul className={styles["ul"]}>
                                                      <li
                                                            className={
                                                                  styles["li"]
                                                            }
                                                      >
                                                            <b
                                                                  className={
                                                                        styles[
                                                                              "heading"
                                                                        ] +
                                                                        " " +
                                                                        styles[
                                                                              "heading-" +
                                                                                    settings.theme
                                                                        ]
                                                                  }
                                                            >
                                                                  beginner mode:
                                                            </b>
                                                            <br></br> This mode
                                                            has lessons for
                                                            absolute beginners.
                                                            practise alphabets,
                                                            symbols, numbers,
                                                            other special
                                                            characters and get
                                                            ready for speed
                                                            mode.
                                                      </li>
                                                      <li
                                                            className={
                                                                  styles["li"]
                                                            }
                                                      >
                                                            <b
                                                                  className={
                                                                        styles[
                                                                              "heading"
                                                                        ] +
                                                                        " " +
                                                                        styles[
                                                                              "heading-" +
                                                                                    settings.theme
                                                                        ]
                                                                  }
                                                            >
                                                                  speed mode:
                                                            </b>
                                                            <br></br> This mode
                                                            lets you test your
                                                            typing skills. It
                                                            consists of
                                                            different sets of
                                                            different sizes.
                                                            Stats for each and
                                                            each timer in a
                                                            set.Take as many
                                                            tests as you can and
                                                            reach you goal.
                                                      </li>
                                                      <li
                                                            className={
                                                                  styles["li"]
                                                            }
                                                      >
                                                            <b
                                                                  className={
                                                                        styles[
                                                                              "heading"
                                                                        ] +
                                                                        " " +
                                                                        styles[
                                                                              "heading-" +
                                                                                    settings.theme
                                                                        ]
                                                                  }
                                                            >
                                                                  practise mode:
                                                            </b>
                                                            <br></br>
                                                            This mode lets you
                                                            practise individual
                                                            words. Practise the
                                                            words that you are
                                                            often making
                                                            mistakes and improve
                                                            your typing skills.
                                                      </li>
                                                </ul>
                                          </li>
                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      Lannguages and ranges:{" "}
                                                </b>
                                                <br></br>
                                                There are multiple Lannguages
                                                and sets to choose from. each
                                                set has fixed number of words
                                                ,numbers,special characters.
                                                Each set has its own stats. each
                                                set has enough words so that way
                                                you will be able to test
                                                yourself or practise words from
                                                that set without feeling of too
                                                many words if you are a
                                                beginner.
                                          </li>
                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      Mode two:{" "}
                                                </b>
                                                <br></br>
                                                This mode is active in practise
                                                mode. To simulate real test
                                                experience you can choose to
                                                have a random word paired with
                                                the actual word.
                                          </li>
                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      Filter by speed:{" "}
                                                </b>
                                                <br></br>
                                                This mode is active in practise
                                                mode. This mode has the average
                                                speed of last 20 speeds of each
                                                word that you typed in test
                                                mode. This gives you better
                                                understanding of which words you
                                                are often making mistakes and
                                                practise accordingly to improve.
                                          </li>
                                    </ul>
                              </div>

                              <h3
                                    className={
                                          styles["heading"] +
                                          " " +
                                          styles["heading-" + settings.theme]
                                    }
                              >
                                    keyboard controls
                              </h3>
                              <div
                                    className={
                                          styles["content"] +
                                          " " +
                                          styles["content-" + settings.theme]
                                    }
                              >
                                    <p className={styles["p"]}>
                                          press tab to navigate between
                                          previous,reload,next buttons and press
                                          enter to select them
                                    </p>
                              </div>
                              <h3
                                    className={
                                          styles["heading"] +
                                          " " +
                                          styles["heading-" + settings.theme]
                                    }
                              >
                                    Why MINITYPE
                              </h3>
                              <div
                                    className={
                                          styles["content"] +
                                          " " +
                                          styles["content-" + settings.theme]
                                    }
                              >
                                    <ol>
                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      User friendly interface
                                                </b>
                                                <br></br>
                                                <p className={styles["p"]}>
                                                      Keeping this in mind,
                                                      MINITYPE was built to be
                                                      simple,elegant and easy to
                                                      use. I am sure it is quiet
                                                      evident once you
                                                      experience it.
                                                </p>
                                          </li>

                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      Lessons for beginners
                                                </b>
                                                <br></br>
                                                <p className={styles["p"]}>
                                                      You dont need to worry
                                                      even if you dont know
                                                      touch typing. MINITYPE has
                                                      lessons where you can
                                                      start your touch typing
                                                      journey. It also provides
                                                      finger mapping for each
                                                      key. Lessons cover keys
                                                      like alphabets,numbers,
                                                      symbols, etc.
                                                </p>
                                          </li>
                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      Not too much customization
                                                </b>
                                                <br></br>
                                                <p className={styles["p"]}>
                                                      This is one of the
                                                      features of MINITYPE,
                                                      there arent too many
                                                      unecessary options nor too
                                                      less important options.
                                                      MINITYPE has balanced
                                                      enough customization like
                                                      different word
                                                      sets,lessons,practise
                                                      mode,stats,themes, timers,
                                                      sounds, which are
                                                      essential
                                                </p>
                                          </li>
                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      Detailed Statistics and
                                                      Progress Tracking
                                                </b>
                                                <br></br>
                                                <p className={styles["p"]}>
                                                      This is one of the most
                                                      important feature for
                                                      improving typing skills.
                                                      MINITYPE has all the stats
                                                      like speed,
                                                      accuracy,highest speed of
                                                      a test,total time spent in
                                                      tests for all the tests.
                                                      But we know that your all
                                                      time averages is not the
                                                      reflection of your current
                                                      skills , so MINITYPE also
                                                      calculates averages of the
                                                      last 20 tests which is far
                                                      more better indication of
                                                      your current skills. The
                                                      best part is these stats
                                                      are displayed right on the
                                                      typing page , so that you
                                                      dont need to go to other
                                                      pages to contantly check
                                                      your stats. stats being
                                                      displayed right on the
                                                      typing page are just a
                                                      glance away.
                                                </p>
                                          </li>

                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      There are too many words
                                                      to improve(Varied Word
                                                      Sets )?
                                                </b>
                                                <br></br>
                                                <p className={styles["p"]}>
                                                      MINITYPE knows that too
                                                      many words can be
                                                      overwhelming and takes too
                                                      long to improve speed and
                                                      accuracy . MINITYPE has
                                                      different sizes of sets of
                                                      words which can reach
                                                      close to 350 words. Having
                                                      smaller set of words like
                                                      100 words will make you
                                                      increase speeds,accuracy
                                                      in quicker time and more
                                                      concentration per word.
                                                      All these different sets
                                                      of words have their own
                                                      stats so that you know
                                                      where you stand in a
                                                      particular set.
                                                </p>
                                          </li>

                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      Are the test word
                                                      positions generated
                                                      randomly, what if i type
                                                      some words too many times
                                                      and some words lot less?
                                                </b>
                                                <br></br>
                                                <p className={styles["p"]}>
                                                      Lot of the other typing
                                                      websites do this and
                                                      MINITYPE knows that
                                                      randomly generating word
                                                      positions can make you
                                                      type some words too many
                                                      times and some words a lot
                                                      lesser over a period of
                                                      time as it is not
                                                      guaranteed. But MINITYPE
                                                      has an algorithm where it
                                                      tracks how many times you
                                                      have typed a word in all
                                                      tests and generates the
                                                      tests word positions from
                                                      least frequently typed too
                                                      highest frequently typed
                                                      word(also shuffles the
                                                      words so that there wont
                                                      be same order of words).
                                                      This way all the words are
                                                      typed almost evenly and
                                                      also randomly. This is one
                                                      of the best features of
                                                      MINITYPE.
                                                </p>
                                          </li>

                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      I want to practise words
                                                      that i am not good at?
                                                </b>
                                                <br></br>
                                                <p className={styles["p"]}>
                                                      Oh yes you really should
                                                      be able to do that. It is
                                                      one of the essential
                                                      feature to practise words
                                                      that you are not so good
                                                      at. MINITYPE has another
                                                      feature called "PRACTISE
                                                      MODE" where it has speed
                                                      data of all the words of
                                                      your tests and categorises
                                                      into different speeds from
                                                      low to high. Here you can
                                                      practise individual words
                                                      as long as you want to.
                                                </p>
                                          </li>

                                          <li className={styles["li"]}>
                                                <b
                                                      className={
                                                            styles["heading"] +
                                                            " " +
                                                            styles[
                                                                  "heading-" +
                                                                        settings.theme
                                                            ]
                                                      }
                                                >
                                                      I want to improve my
                                                      numpad skills?
                                                </b>
                                                <br></br>
                                                <p className={styles["p"]}>
                                                      MINITYPE does have a set
                                                      where all of them are just
                                                      numbers of different
                                                      lengths where you can test
                                                      your numpad skills.This is
                                                      essential for people who
                                                      use numpad a lot.
                                                </p>
                                          </li>
                                    </ol>
                              </div>
                        </div>

                        <h3
                              className={
                                    styles["heading"] +
                                    " " +
                                    styles["heading-" + settings.theme]
                              }
                        >
                              Summary
                        </h3>
                        <div
                              className={
                                    styles["content"] +
                                    " " +
                                    styles["content-" + settings.theme]
                              }
                        >
                              <ul className={styles["ul"]}>
                                    <li className={styles["li"]}>
                                          Lessons for absolute beginners
                                    </li>
                                    <li className={styles["li"]}>
                                          different sets of words with different
                                          sizes which can go upto 350 words per
                                          set
                                    </li>
                                    <li className={styles["li"]}>
                                          set of only numbers of different
                                          lengths to practise numpad
                                    </li>
                                    <li className={styles["li"]}>
                                          essential stats like average
                                          speed,accuracy, total tests of all
                                          tests and also for last 20 tests
                                    </li>
                                    <li className={styles["li"]}>
                                          stats for every set of words
                                    </li>
                                    <li className={styles["li"]}>
                                          multiple themes
                                    </li>
                                    <li className={styles["li"]}>
                                          multiple sound effects on keypress
                                    </li>

                                    <li className={styles["li"]}>
                                          displaying stats on the typing page
                                          for quicker access
                                    </li>
                                    <li className={styles["li"]}>
                                          practise mode for practising
                                          individual words , which also
                                          categorises words based on average
                                          speed of each word from its last 20
                                          speeds.
                                    </li>
                                    <li className={styles["li"]}>
                                          genrating word positions in a way that
                                          all the words are typed almost evenly
                                          which inturn gives correct stats.
                                    </li>
                                    <li className={styles["li"]}>
                                          simple , elegant,easy to use
                                          experience.
                                    </li>
                              </ul>
                        </div>
                  </main>
            </CardOne>
      );
};
