import {
      SettingsModel,
      BeginnerModeStatsModel,
      StatsModel,
} from "../models/models";
import {
      languagesAndWords,
      calculateAverageAccuracy,
      calculateAverageWpm,
} from "../utilities/utilities";

export const updateUserSettings = async (request, response) => {
      try {
            if (request.body["language and range"]) {
                  request.body["language and range"] = JSON.parse(
                        request.body["language and range"]
                  );
            }

            let userSettings = SettingsModel.findOne();

            if (!userSettings) {
                  userSettings = new SettingsModel({
                        ...request.body,
                  });
            } else {
                  for (let [key, value] of Object.entries(request.body)) {
                        userSettings[key] = value;
                  }
            }

            SettingsModel.save(userSettings);
            return {
                  status: "success",
                  message: "successfully updated settings",
            };
      } catch (error) {
            return {
                  status: "error",
                  message: error.message,
            };
      }
};

export const postBeginnerModeStats = async (request, response) => {
      try {
            const testStats = request.body;
            let beginnerModeStats = BeginnerModeStatsModel.findOne();
            if (!beginnerModeStats) {
                  beginnerModeStats = new BeginnerModeStatsModel({
                        lessons: [
                              {
                                    testsAttempted: 1,
                                    highestSpeed: testStats.wpm,
                                    lessonId: testStats.lesson,
                              },
                        ],
                  });
            } else {
                  let lessonDoc = null;
                  for (let i = 0; i < beginnerModeStats.lessons.length; i++) {
                        const lesson = beginnerModeStats.lessons[i];
                        if (lesson.lessonId === testStats.lesson) {
                              lessonDoc = lesson;
                              break;
                        }
                  }

                  if (lessonDoc) {
                        lessonDoc.testsAttempted++;
                        if (lessonDoc.highestSpeed < testStats.wpm) {
                              lessonDoc.highestSpeed = testStats.wpm;
                        }
                  } else {
                        const newLesson = {
                              testsAttempted: 1,
                              highestSpeed: testStats.wpm,
                              lessonId: testStats.lesson,
                        };
                        beginnerModeStats.lessons.push(newLesson);
                  }
            }

            BeginnerModeStatsModel.save(beginnerModeStats);
            return {
                  status: "success",
                  message: "successfully updated stats",
            };
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const postUserLanguageSubTypeStats = async (request, response) => {
      try {
            const testStats = request.body;
            const { language, optionIndex } = testStats.languageAndRange;

            // testStats.date = new Date().toISOString();

            //validate data
            const languageData = languagesAndWords.hasOwnProperty(language)
                  ? languagesAndWords[language]
                  : false;

            if (!languageData) {
                  throw new Error("invalid language");
            }

            const option = languageData.options.hasOwnProperty(optionIndex)
                  ? languageData.options[optionIndex]
                  : false;

            if (!option) {
                  throw new Error("invalid option");
            }

            const words = {};
            option.wordsSlices.forEach((slice) => {
                  for (let i = slice[0]; i <= slice[1]; i++) {
                        words[languageData.words[i]] = 1;
                  }
            });

            for (let i of Object.keys(testStats.wordsStats)) {
                  if (!words.hasOwnProperty(i)) {
                        throw new Error("invalid words stats data");
                  } else {
                        let wordStats = testStats.wordsStats[i];
                        if (
                              wordStats.wpm < 0 ||
                              wordStats.wpm > 700 ||
                              wordStats.accuracy > 100 ||
                              wordStats.accuracy < 0
                        ) {
                              throw new Error("invalid data");
                        }
                  }
            }

            let userStats = StatsModel.findOne({
                  language: language,
                  optionIndex: optionIndex,
                  subName: languageData.options[optionIndex].name,
            });

            if (!userStats) {
                  userStats = new StatsModel({
                        language: language,
                        optionIndex: optionIndex,
                        subName: languageData.options[optionIndex].name,
                  });
            }

            if (testStats.mode === "test") {
                  let x = userStats.testMode;

                  // speed distribution
                  const remainder = testStats.wpm % 10;
                  const quotient = testStats.wpm - remainder;
                  let startingPoint;
                  let endingPoint;
                  let range;
                  if (testStats.wpm <= 10) {
                        startingPoint = 0;
                  } else if (remainder === 0) {
                        startingPoint = quotient - 10 + 1;
                        endingPoint = quotient;
                        range = `${startingPoint}-${endingPoint}`;
                  } else {
                        startingPoint = quotient + 1;
                        endingPoint = quotient + 10;
                        range = `${startingPoint}-${endingPoint}`;
                  }

                  if (!x.speedDistribution[startingPoint]) {
                        x.speedDistribution[startingPoint] = 1;
                  } else {
                        x.speedDistribution[startingPoint]++;
                  }

                  if (!x.firstTest) {
                        x.firstTest = new Date(testStats.date);
                  }
                  x.lastTest = new Date(testStats.date);
                  // x.totalTimeSpentsInTests += testStats.timer;

                  x.averageAccuracy = calculateAverageAccuracy(
                        x.averageAccuracy,
                        x.totalNumberOfFinishedTests,
                        testStats.accuracy
                  );

                  x.averageWpm = calculateAverageWpm(
                        x.averageWpm,
                        x.totalNumberOfFinishedTests,
                        testStats.wpm
                  );

                  x.totalNumberOfFinishedTests++;

                  if (x.highestWpmOfATest.wpm < testStats.wpm) {
                        x.highestWpmOfATest.wpm = testStats.wpm;
                        x.highestWpmOfATest.accuracy = testStats.accuracy;
                        x.highestWpmOfATest.date = new Date(testStats.date);
                  } else if (x.highestWpmOfATest.wpm === testStats.wpm) {
                        if (
                              x.highestWpmOfATest.accuracy <= testStats.accuracy
                        ) {
                              x.highestWpmOfATest.accuracy = testStats.accuracy;
                              x.highestWpmOfATest.date = new Date(
                                    testStats.date
                              );
                        }
                  }

                  if (x.lastTwentyTests.length === 20) {
                        x.lastTwentyTests.shift();
                  }
                  x.lastTwentyTests.push({
                        wpm: testStats.wpm,
                        accuracy: testStats.accuracy,
                        date: new Date(testStats.date),
                        timer: testStats.timer,
                  });

                  x = x["timerBasedStats"][testStats.timer];

                  if (
                        testStats.timer === 15 ||
                        testStats.timer === 30 ||
                        testStats.timer === 60
                  ) {
                        x.averageWpm = calculateAverageWpm(
                              x.averageWpm,
                              x.totalNumberOfFinishedTests,
                              testStats.wpm
                        );

                        x.averageAccuracy = calculateAverageAccuracy(
                              x.averageAccuracy,
                              x.totalNumberOfFinishedTests,
                              testStats.accuracy
                        );

                        x.totalNumberOfFinishedTests++;

                        if (x.highestWpmOfATest.wpm <= testStats.wpm) {
                              x.highestWpmOfATest.wpm = testStats.wpm;
                              x.highestWpmOfATest.accuracy = testStats.accuracy;
                              x.highestWpmOfATest.date = new Date(
                                    testStats.date
                              );
                        }

                        if (x.highestWpmOfATest.wpm < testStats.wpm) {
                              x.highestWpmOfATest.wpm = testStats.wpm;
                              x.highestWpmOfATest.accuracy = testStats.accuracy;
                              x.highestWpmOfATest.date = new Date(
                                    testStats.date
                              );
                        } else if (x.highestWpmOfATest.wpm === testStats.wpm) {
                              if (
                                    x.highestWpmOfATest.accuracy <=
                                    testStats.accuracy
                              ) {
                                    x.highestWpmOfATest.accuracy =
                                          testStats.accuracy;
                                    x.highestWpmOfATest.date = new Date(
                                          testStats.date
                                    );
                              }
                        }

                        if (x.lastTwentyTests.length >= 20) {
                              x.lastTwentyTests.shift();
                        }
                        x.lastTwentyTests.push({
                              wpm: testStats.wpm,
                              accuracy: testStats.accuracy,
                              date: new Date(testStats.date),
                        });
                  }

                  let y = userStats.testMode.wordsStats;
                  for (let [key, value] of Object.entries(
                        testStats.wordsStats
                  )) {
                        if (value.endedAt !== undefined) {
                              if (y[key] === undefined) {
                                    y[key] = {
                                          totalNumberOfTestsAppeared:
                                                value.count,
                                          averageWpm: value.wpm,
                                          averageAccuracy: value.accuracy,
                                          lastTwentyTests: [
                                                {
                                                      wpm: value.wpm,
                                                      accuracy: value.accuracy,
                                                },
                                          ],
                                    };
                              } else {
                                    y[key].averageWpm = calculateAverageWpm(
                                          y[key].averageWpm,
                                          y[key].totalNumberOfTestsAppeared,
                                          value.wpm
                                    );

                                    y[key].averageAccuracy =
                                          calculateAverageAccuracy(
                                                y[key].averageAccuracy,
                                                y[key]
                                                      .totalNumberOfTestsAppeared,
                                                value.accuracy
                                          );

                                    y[key].totalNumberOfTestsAppeared +=
                                          value.count;

                                    if (y[key].lastTwentyTests.length === 20) {
                                          y[key].lastTwentyTests.shift();
                                    }
                                    y[key].lastTwentyTests.push({
                                          wpm: value.wpm,
                                          accuracy: value.accuracy,
                                    });
                              }
                        }
                  }

                  //   userStats.markModified("testMode.wordsStats");
                  //   userStats.markModified("testMode.speedDistribution");
                  StatsModel.save(userStats);
            } else if (testStats.mode === "practise") {
                  let y = userStats.practiseMode.wordsStats;

                  for (let [key, value] of Object.entries(
                        testStats.wordsStats
                  )) {
                        if (value.endedAt !== undefined) {
                              if (y[key] === undefined) {
                                    y[key] = {
                                          totalNumberOfTestsAppeared: 1,
                                          averageWpm: value.wpm,
                                          averageAccuracy: value.accuracy,
                                          lastTwentyTests: [
                                                {
                                                      wpm: value.wpm,
                                                      accuracy: value.accuracy,
                                                },
                                          ],
                                    };
                              } else {
                                    y[key].averageWpm = calculateAverageWpm(
                                          y[key].averageWpm,
                                          y[key].totalNumberOfTestsAppeared,
                                          value.wpm
                                    );

                                    y[key].averageAccuracy =
                                          calculateAverageAccuracy(
                                                y[key].averageAccuracy,
                                                y[key]
                                                      .totalNumberOfTestsAppeared,
                                                value.accuracy
                                          );

                                    y[key].totalNumberOfTestsAppeared++;

                                    if (y[key].lastTwentyTests.length === 3) {
                                          y[key].lastTwentyTests.shift();
                                          y[key].lastTwentyTests.push({
                                                wpm: value.wpm,
                                                accuracy: value.accuracy,
                                          });
                                    } else {
                                          y[key].lastTwentyTests.push({
                                                wpm: value.wpm,
                                                accuracy: value.accuracy,
                                          });
                                    }
                              }
                        }
                  }
                  //   userStats.markModified("practiseMode.wordsStats");
                  StatsModel.save(userStats);
            } else {
                  throw new Error("invalid data");
            }
            return {
                  status: "success",
                  message: "saved succesfully",
            };
      } catch (error) {
            return {
                  status: "error",
                  message: error.message,
            };
      }
};
