import styles from "../pages/homePage/typingParagraph.module.css";
export const getToken = () => {
      return JSON.parse(localStorage.getItem("token"));
};

export const defaultSettings = {
      timer: 15,

      theme: "theme-4",
      sound: "soundC",
      "language and range": {
            language: "english",
            fullName: "english (1-100)",
            optionIndex: 0,
      },
      mode: "test",
      modeOne: "words",
      modeThree: 1000,
      modeTwo: "0",
      modeFour: 0,
      textEffect: "text-effect-3",
};

export const toastOptions = {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
};

export const colorRingOptions = {
      visible: true,
      height: "70",
      width: "70",
      ariaLabel: "blocks-loading",
      wrapperClass: "blocks-wrapper",
      colors: ["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"],
};

export const updateCharactersStats = (action, testStats, currentCharacter) => {
      if (action.type === "right hit") {
            if (testStats["charactersStats"][currentCharacter] === undefined) {
                  testStats["charactersStats"][currentCharacter] = {
                        totalNumberOfRightHits: 1,
                        totalNumberOfWrongHits: 0,
                  };
            } else {
                  testStats["charactersStats"][currentCharacter]
                        .totalNumberOfRightHits++;
            }
            testStats["totalNumberOfRightHits"]++;
      } else {
            if (testStats["charactersStats"][currentCharacter] === undefined) {
                  testStats["charactersStats"][currentCharacter] = {
                        totalNumberOfRightHits: 0,
                        totalNumberOfWrongHits: 1,
                  };
            } else {
                  testStats["charactersStats"][currentCharacter]
                        .totalNumberOfWrongHits++;
            }
            testStats["totalNumberOfWrongHits"]++;
      }
};

export const updateWpmAndAccuracy = (timerState, testStats) => {
      testStats.wpm =
            Math.round(
                  (testStats.totalNumberOfRightHits / 5) *
                        (60 / timerState.elapsedTime)
            ) || 0;
      testStats.accuracy =
            Math.round(
                  (testStats.totalNumberOfRightHits /
                        (testStats.totalNumberOfRightHits +
                              testStats.totalNumberOfWrongHits)) *
                        100
            ) || 0;

      for (let [key, value] of Object.entries(testStats.wordsStats)) {
            if (value.endedAt !== undefined) {
                  value.wpm = (key.length / 5) * (60 / (value.speed / 1000));
                  value.accuracy =
                        (value.rightHitsCount /
                              (value.rightHitsCount + value.wrongHitsCount)) *
                        100;
            }
      }
};

export const createtypingParagraphJsx = (
      words,
      typingState,
      wordRef,
      theme,
      textEffect
) => {
      const paragraph = [];
      let index = -1;
      for (let i = 0; i < words.length; i++) {
            const word = words[i];
            const temporary = [];
            for (let j = 0; j < word.length; j++) {
                  let className = "";
                  index++;
                  if (i < typingState.currentWordIndex) {
                        className =
                              styles["active-right-" + theme] +
                              " " +
                              styles[textEffect];
                  } else if (i === typingState.currentWordIndex) {
                        if (j < typingState.currentLetterIndex) {
                              className =
                                    styles["active-right-" + theme] +
                                    " " +
                                    styles[textEffect];
                        } else if (j === typingState.currentLetterIndex) {
                              if (
                                    typingState.currentLetterClass ===
                                    "active-wrong"
                              ) {
                                    className = styles["active-wrong"];
                              }
                        }
                        if (j === typingState.currentLetterIndex) {
                              if (className === "") {
                                    className = styles["active-next-character"];
                              } else {
                                    className +=
                                          " " + styles["active-next-character"];
                              }
                        }
                  }

                  if (word[j] === " ") {
                        temporary.push(
                              <span
                                    className={
                                          styles["letter"] + " " + className
                                    }
                              >
                                    &nbsp;
                              </span>
                        );
                  } else {
                        temporary.push(
                              <span
                                    //
                                    className={
                                          styles["letter"] + " " + className
                                    }
                              >
                                    {word[j]}
                              </span>
                        );
                  }
            }
            if (
                  typingState.paragraphNextIndex <= index &&
                  typingState.paragraphNextIndex > index - word.length
            ) {
                  paragraph.push(
                        <div ref={wordRef} className={styles["word"]}>
                              {temporary}
                        </div>
                  );
            } else {
                  paragraph.push(
                        <div className={styles["word"]}>{temporary}</div>
                  );
            }
      }

      return paragraph;
};

export const initialTypingState = {
      paragraphCurrentIndex: -1,
      paragraphNextIndex: 0,
      currentLetterClass: "typing-letter",
      started: false,
      finished: false,
      currentWordIndex: 0,
      currentLetterIndex: 0,
};

export const positionSpecialCharactersInBetweenWords = (
      wordsSlice,
      numbersSlice
) => {
      shuffle(wordsSlice);
      shuffle(numbersSlice);
      const finalWords = [];
      let i = 0;
      let j = 0;
      let stopByLength =
            numbersSlice.length === 0
                  ? 1
                  : Math.round(wordsSlice.length / numbersSlice.length);
      while (i < wordsSlice.length || j < numbersSlice.length) {
            if (i < wordsSlice.length) {
                  for (let k = 0; k < stopByLength; k++) {
                        if (i < wordsSlice.length) {
                              finalWords.push(wordsSlice[i]);
                              i++;
                        }
                  }
            }
            if (j < numbersSlice.length) {
                  finalWords.push(numbersSlice[j]);
            }

            j++;
      }

      return finalWords;
};

export const wordsMixer = (words, statsData, languageAndRange) => {
      let wordsSlice = [];
      let numbersSlice = [];
      if (languageAndRange.language === "english") {
            if (languageAndRange.optionIndex === 4) {
                  wordsSlice = words.slice(0, 60);
                  numbersSlice = words.slice(60);
            } else if (languageAndRange.optionIndex === 6) {
                  wordsSlice = words.slice(0, 60);
                  numbersSlice = words.slice(60);
            } else if (
                  languageAndRange.optionIndex === 7 ||
                  languageAndRange.optionIndex === 8 ||
                  languageAndRange.optionIndex === 9
            ) {
                  wordsSlice = words.slice(0, 300);
                  numbersSlice = words.slice(300);
            } else {
                  wordsSlice = [...words];
            }
      } else if (languageAndRange.language === "javascript") {
            wordsSlice = [...words];
      }

      shuffle(wordsSlice);
      shuffle(numbersSlice);

      const newWordsSlice = wordsSlice.map((word) => {
            const wordStats = statsData?.testMode?.wordsStats;

            if (wordStats) {
                  return {
                        word,
                        count: wordStats[word]?.totalNumberOfTestsAppeared || 0,
                  };
            } else {
                  return {
                        word,
                        count: 0,
                  };
            }
      });
      const newNumbersSlice = numbersSlice.map((word) => {
            const wordStats = statsData?.testMode?.wordsStats;

            if (wordStats) {
                  return {
                        word,
                        count: wordStats[word]?.totalNumberOfTestsAppeared || 0,
                  };
            } else {
                  return {
                        word,
                        count: 0,
                  };
            }
      });

      newWordsSlice.sort((a, b) => {
            return a.count - b.count;
      });
      newNumbersSlice.sort((a, b) => {
            return a.count - b.count;
      });

      const finalWords = [];
      let i = 0;
      let j = 0;
      let stopByLength =
            numbersSlice.length === 0
                  ? 1
                  : Math.round(wordsSlice.length / numbersSlice.length);
      while (i < newWordsSlice.length || j < newNumbersSlice.length) {
            if (i < newWordsSlice.length) {
                  for (let k = 0; k < stopByLength; k++) {
                        if (i < newWordsSlice.length) {
                              finalWords.push(newWordsSlice[i].word);
                              i++;
                        }
                  }
            }
            if (j < newNumbersSlice.length) {
                  finalWords.push(newNumbersSlice[j].word);
            }

            j++;
      }

      return finalWords;
};

export const wordsMixerOne = (props) => {
      let words = [];
      words.push(" ");

      if (props.settings.mode === "test") {
            const mixedWords = wordsMixer(
                  props.allWords,
                  props.statsData,
                  props.settings["language and range"]
            );

            for (let i = 0; i < props.allWords.length; i++) {
                  words.push(mixedWords[i], " ");
            }
      } else {
            if (props.settings.mode === "beginner") {
                  beginnerMode(props, words);
                  return words;
                  if (props.settings.modeTwo === "1") {
                        const letter = props.allWords[props.wordIndex];
                        const randomLetter =
                              props.allLetters[Math.floor(Math.random() * 25)];

                        for (let i = 0; i < 100; i++) {
                              let randomLength =
                                    Math.floor(Math.random() * 3) + 1;

                              words.push(letter.repeat(randomLength));

                              words.push(" ");

                              randomLength = Math.floor(Math.random() * 3) + 1;

                              words.push(randomLetter.repeat(randomLength));
                              words.push(" ");
                        }
                  } else {
                        const letter = props.allWords[props.wordIndex];
                        for (let i = 0; i < 100; i++) {
                              const randomLength =
                                    Math.floor(Math.random() * 3) + 1;

                              words.push(letter.repeat(randomLength));
                              words.push(" ");
                        }
                  }
            } else {
                  if (
                        props.settings.modeTwo === "1" &&
                        props.practiseModeAllWords.length > 0
                  ) {
                        const word =
                              props.practiseModeAllWords[props.wordIndex];
                        const randomWord =
                              props.allWords[
                                    Math.floor(
                                          Math.random() * props.allWords.length
                                    )
                              ];

                        for (let i = 0; i < 100; i++) {
                              let randomLength =
                                    Math.floor(Math.random() * 2) + 1;

                              for (let i = 0; i < randomLength; i++) {
                                    words.push(word);
                                    words.push(" ");
                              }

                              randomLength = Math.floor(Math.random() * 2) + 1;

                              for (let i = 0; i < randomLength; i++) {
                                    words.push(randomWord);
                                    words.push(" ");
                              }
                        }
                  } else {
                        for (let i = 0; i < 100; i++) {
                              if (props.practiseModeAllWords.length > 0) {
                                    words.push(
                                          props.practiseModeAllWords[
                                                props.wordIndex
                                          ]
                                    );
                                    words.push(" ");
                              }
                        }
                  }
            }
      }
      return words;
};

export const shuffle = (input) => {
      let size = input.length;

      while (size > 0) {
            const randomIndex = Math.floor(Math.random() * size);
            size--;
            const temp = input[size];
            input[size] = input[randomIndex];
            input[randomIndex] = temp;
      }
};

export const highestAverageSpeedOfAWord = (statsData) => {
      statsData.testMode.highestAverageSpeedOfAWord = Object.entries(
            statsData.testMode.wordsStats || {}
      ).reduce(
            (total, current) => {
                  if (total.speed < current[1].averageWpm) {
                        total.speed = current[1].averageWpm;
                        total.word = current[0];
                        return total;
                  } else {
                        return total;
                  }
            },
            {
                  word: undefined,
                  speed: -1,
            }
      );
};
export const highestAverageAcuuracyOfAWord = (statsData) => {
      statsData.testMode.highestAverageAccuracyOfAWord = Object.entries(
            statsData.testMode.wordsStats || {}
      ).reduce(
            (total, current) => {
                  if (total.accuracy < current[1].averageWpm) {
                        total.accuracy = current[1].averageAccuracy;
                        total.word = current[0];
                        return total;
                  } else {
                        return total;
                  }
            },
            {
                  word: undefined,
                  accuracy: -1,
            }
      );
};

export const lastTwentyTestsAverages = (lastTwentyTests) => {
      const [wpmSum, accuracySum] = lastTwentyTests.reduce(
            (total, current, index) => {
                  if (index > 19) {
                        return total;
                  }
                  return [total[0] + current.wpm, total[1] + current.accuracy];
            },
            [0, 0]
      );
      let lastTwentyTestsAverageWpm = wpmSum / lastTwentyTests.length;
      let lastTwentyTestsAverageAccuracy = accuracySum / lastTwentyTests.length;

      if (isNaN(lastTwentyTestsAverageAccuracy)) {
            lastTwentyTestsAverageAccuracy = 0;
      }
      if (isNaN(lastTwentyTestsAverageWpm)) {
            lastTwentyTestsAverageWpm = 0;
      }
      return [lastTwentyTestsAverageWpm, lastTwentyTestsAverageAccuracy];
};

export const beginnerModeData = [
      {
            category: "letter",
            data: ["f", "j"],
            name: "keys - (f, j)",
            lessonId: 0,
      },
      {
            category: "letter",
            data: ["d", "k"],
            name: "keys - (d, k)",
            lessonId: 1,
      },
      {
            category: "letter",
            data: ["s", "l"],
            name: "keys - (s, l)",
            lessonId: 2,
      },
      {
            category: "letter",
            data: ["a", "m"],
            name: "keys - (a, m)",
            lessonId: 3,
      },
      {
            category: "letter",
            data: ["g", "h"],
            name: "keys - (g, h)",
            lessonId: 4,
      },
      {
            category: "letter",
            data: ["q", "p"],
            name: "keys - (q, p)",
            lessonId: 5,
      },
      {
            category: "letter",
            data: ["w", "o"],
            name: "keys - (w, o)",
            lessonId: 6,
      },
      {
            category: "letter",
            data: ["e", "i"],
            name: "keys - (e, i)",
            lessonId: 7,
      },
      {
            category: "letter",
            data: ["r", "u"],
            name: "keys - (r, u)",
            lessonId: 8,
      },
      {
            category: "letter",
            data: ["t", "y"],
            name: "keys - (t, y)",
            lessonId: 9,
      },
      {
            category: "letter",
            data: ["z", "n"],
            name: "keys - (z, n)",
            lessonId: 10,
      },
      {
            category: "letter",
            data: ["x", "b"],
            name: "keys - (x, b)",
            lessonId: 11,
      },
      {
            category: "letter",
            data: ["c", "v"],
            name: "keys - (c, v)",
            lessonId: 12,
      },
      {
            category: "letter",
            data: [
                  "f",
                  "j",
                  "d",
                  "k",
                  "s",
                  "l",
                  "a",
                  "m",
                  "g",
                  "h",
                  "q",
                  "p",
                  "w",
                  "o",
            ],
            name: "alphabets mix - 1",
            lessonId: 13,
      },
      {
            category: "letter",
            data: ["e", "i", "r", "u", "t", "y", "z", "n", "x", "b", "c", "v"],
            name: "alphabets mix - 2",
            lessonId: 14,
      },
      {
            category: "words",
            data: ["as", "wrong"],
            name: "words - (as, wrong)",
            lessonId: 15,
      },
      {
            category: "words",
            data: ["his", "that"],
            name: "words - (his, that)",
            lessonId: 16,
      },
      {
            category: "words",
            data: ["he", "was"],
            name: "words - (he, was)",
            lessonId: 17,
      },
      {
            category: "words",
            data: ["for", "on"],
            name: "words - (for, on)",
            lessonId: 18,
      },
      {
            category: "words",
            data: ["as", "wrong", "his", "that", "he", "was", "for", "on"],
            name: "all words",
            lessonId: 19,
      },
      {
            category: "letter",
            data: ["0", "1"],
            name: "keys - (0, 1)",
            lessonId: 20,
      },
      {
            category: "letter",
            data: ["2", "9"],
            name: "keys - (2, 9)",
            lessonId: 21,
      },
      {
            category: "letter",
            data: ["3", "8"],
            name: "keys - (3, 8)",
            lessonId: 22,
      },
      {
            category: "letter",
            data: ["4", "7"],
            name: "keys - (4, 7)",
            lessonId: 23,
      },
      {
            category: "letter",
            data: ["5", "6"],
            name: "keys - (5, 6)",
            lessonId: 24,
      },
      {
            category: "letter",
            data: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
            name: "all numbers mix ",
            lessonId: 25,
      },
      {
            category: "mix",
            data: [
                  "as",
                  "wrong",
                  "his",
                  "that",
                  "he",
                  "was",
                  "for",
                  "on",
                  "are",
                  "with",
                  "they",
                  "be",
                  "at",
                  "one",
                  "have",
                  "this",
                  "85",
                  "70",
                  "26",
                  "49",
                  "31",
                  "94",
                  "36",
                  "58",
            ],
            slices: [
                  [0, 16],
                  [16, 24],
            ],
            name: "(numbers + words) mix",
            lessonId: 26,
      },
      {
            category: "letter",
            data: ["~", ")"],
            name: "keys - (~, ))",
            lessonId: 27,
      },
      {
            category: "letter",
            data: ["!", "_"],
            name: "keys - (!, _)",
            lessonId: 28,
      },
      {
            category: "letter",
            data: ["@", "+"],
            name: "keys - (@, +)",
            lessonId: 29,
      },
      {
            category: "letter",
            data: ["#", "("],
            name: "keys - (#, ()",
            lessonId: 30,
      },
      {
            category: "letter",
            data: ["$", "*"],
            name: "keys - ($, *)",
            lessonId: 31,
      },
      {
            category: "letter",
            data: ["%", "&"],
            name: "keys - (%, &)",
            lessonId: 32,
      },
      {
            category: "letter",
            data: ["^", "|"],
            name: "keys - (^, |)",
            lessonId: 33,
      },
      {
            category: "letter",
            data: ["{", '"'],
            name: 'keys - ({, ")',
            lessonId: 34,
      },
      {
            category: "letter",
            data: [":", "}"],
            name: "keys - (:, })",
            lessonId: 35,
      },
      {
            category: "letter",
            data: ["<", "?"],
            name: "keys - (<, ?)",
            lessonId: 36,
      },
      {
            category: "letter",
            data: [">", "}"],
            name: "keys - (>, })",
            lessonId: 37,
      },
      {
            category: "mix",
            data: [
                  "as",
                  "wrong",
                  "his",
                  "that",
                  "he",
                  "was",
                  "for",
                  "on",
                  "are",
                  "with",
                  "they",
                  "be",
                  "at",
                  "one",
                  "have",
                  "this",
                  "from",
                  "by",
                  "hot",
                  "word",
                  "~",
                  "$",
                  "&",
                  "*",
                  "#",
                  "@",
                  "(",
                  "!",
                  ")",
                  "%",
            ],
            slices: [
                  [0, 20],
                  [20, 30],
            ],
            name: "(special keys-1 + words) mix - 1",
            lessonId: 38,
      },
      {
            category: "mix",
            data: [
                  "as",
                  "wrong",
                  "his",
                  "that",
                  "he",
                  "was",
                  "for",
                  "on",
                  "are",
                  "with",
                  "they",
                  "be",
                  "at",
                  "one",
                  "have",
                  "this",
                  "from",
                  "by",
                  "hot",
                  "word",
                  "but",
                  "what",
                  "^",
                  "_",
                  "+",
                  "{",
                  "}",
                  "|",
                  ":",
                  '"',
                  "<",
                  ">",
                  "?",
            ],
            slices: [
                  [0, 22],
                  [22, 33],
            ],
            name: "(special keys-1 + words) mix - 2",
            lessonId: 39,
      },
      {
            category: "letter",
            data: ["-", "."],
            name: "keys - (-, .)",
            lessonId: 40,
      },
      {
            category: "letter",
            data: ["=", ","],
            name: "keys - (=, ,)",
            lessonId: 41,
      },
      {
            category: "letter",
            data: ["\\", "/"],
            name: "keys - (\\, /)",
            lessonId: 42,
      },
      {
            category: "letter",
            data: ["[", ";"],
            name: "keys - ([, ;)",
            lessonId: 43,
      },
      {
            category: "letter",
            data: ["]", "'"],
            name: "keys - (], ')",
            lessonId: 44,
      },
      {
            category: "mix",
            data: [
                  "as",
                  "wrong",
                  "his",
                  "that",
                  "he",
                  "was",
                  "for",
                  "on",
                  "are",
                  "with",
                  "they",
                  "be",
                  "at",
                  "one",
                  "have",
                  "this",
                  "from",
                  "by",
                  "hot",
                  "word",
                  "-",
                  "=",
                  "[",
                  "]",
                  "\\",
                  ";",
                  "'",
                  ",",
                  ".",
                  "/",
            ],
            slices: [
                  [0, 20],
                  [20, 30],
            ],
            name: "(special keys-2 + words) mix",
            lessonId: 45,
      },
];

export const beginnerMode = (props, words) => {
      const item = beginnerModeData[props.settings.modeFour || 0];

      if (item.category === "letter") {
            while (words.length < 150) {
                  shuffle(item.data);
                  for (let i = 0; i < item.data.length; i++) {
                        let letter = item.data[i];
                        let randomLength = Math.floor(Math.random() * 2) + 1;

                        words.push(letter.repeat(randomLength));
                        words.push(" ");
                  }
            }
      } else if (item.category === "words") {
            while (words.length < 100) {
                  shuffle(item.data);
                  for (let i = 0; i < item.data.length; i++) {
                        const randomLength = Math.floor(Math.random() * 2) + 1;
                        const word = item.data[i];
                        for (let i = 0; i < randomLength; i++) {
                              words.push(word);
                              words.push(" ");
                        }
                  }
            }
      } else if (item.category === "mix") {
            const wordsSlice = item.data.slice(...item.slices[0]);
            const otherSlice = item.data.slice(...item.slices[1]);

            while (words.length < 100) {
                  const temp = positionSpecialCharactersInBetweenWords(
                        wordsSlice,
                        otherSlice
                  );

                  for (let i = 0; i < temp.length; i++) {
                        words.push(temp[i], " ");
                  }
            }
      }
};

export const keyToImageMapping = {
      " ": { hand: "rightHandThumbFinger" },
      a: { hand: "leftHandPinkyFinger" },
      b: { hand: "leftHandIndexFinger" },
      c: { hand: "leftHandMiddleFinger" },
      d: { hand: "leftHandMiddleFinger" },
      e: { hand: "leftHandMiddleFinger" },
      f: { hand: "leftHandIndexFinger" },
      g: { hand: "leftHandIndexFinger" },
      h: { hand: "rightHandIndexFinger" },
      i: { hand: "rightHandMiddleFinger" },
      j: { hand: "rightHandIndexFinger" },
      k: { hand: "rightHandMiddleFinger" },
      l: { hand: "rightHandRingFinger" },
      m: { hand: "leftHandIndexFinger" },
      n: { hand: "leftHandIndexFinger" },
      o: { hand: "rightHandRingFinger" },
      p: { hand: "rightHandPinkyFinger" },
      q: { hand: "leftHandPinkyFinger" },
      r: { hand: "leftHandIndexFinger" },
      s: { hand: "leftHandRingFinger" },
      t: { hand: "leftHandIndexFinger" },
      u: { hand: "rightHandIndexFinger" },
      v: { hand: "leftHandIndexFinger" },
      w: { hand: "leftHandRingFinger" },
      x: { hand: "leftHandRingFinger" },
      y: { hand: "rightHandIndexFinger" },
      z: { hand: "leftHandPinkyFinger" },

      0: { hand: "rightHandPinkyFinger" },
      1: { hand: "leftHandPinkyFinger" },
      2: { hand: "leftHandRingFinger" },
      3: { hand: "leftHandMiddleFinger" },
      4: { hand: "leftHandIndexFinger" },
      5: { hand: "leftHandIndexFinger" },
      6: { hand: "rightHandIndexFinger" },
      7: { hand: "rightHandIndexFinger" },
      8: { hand: "rightHandMiddleFinger" },
      9: { hand: "rightHandRingFinger" },

      //
      "~": { hand: "leftHandPinkyFinger", rightShift: "true" },
      $: { hand: "leftHandIndexFinger", rightShift: "true" },
      "&": { hand: "rightHandIndexFinger", leftShift: "true" },
      "*": { hand: "rightHandMiddleFinger", leftShift: "true" },
      "#": { hand: "leftHandMiddleFinger", rightShift: "true" },
      "@": { hand: "leftHandRingFinger", rightShift: "true" },
      "(": { hand: "rightHandRingFinger", leftShift: "true" },
      "!": { hand: "leftHandPinkyFinger", rightShift: "true" },
      ")": { hand: "rightHandPinkyFinger", leftShift: "true" },
      "%": { hand: "leftHandIndexFinger", rightShift: "true" },
      "^": { hand: "rightHandIndexFinger", leftShift: "true" },
      _: { hand: "rightHandPinkyFinger", leftShift: "true" },
      "+": { hand: "rightHandPinkyFinger", leftShift: "true" },
      "{": { hand: "rightHandPinkyFinger", leftShift: "true" },
      "}": { hand: "rightHandPinkyFinger", leftShift: "true" },
      "|": { hand: "rightHandPinkyFinger", leftShift: "true" },
      ":": { hand: "rightHandPinkyFinger", leftShift: "true" },
      '"': { hand: "rightHandPinkyFinger", leftShift: "true" },
      "<": { hand: "rightHandMiddleFinger", leftShift: "true" },
      ">": { hand: "rightHandRingFinger", leftShift: "true" },
      "?": { hand: "rightHandPinkyFinger", leftShift: "true" },

      //
      "-": { hand: "rightHandPinkyFinger" },
      "=": { hand: "rightHandPinkyFinger" },
      "[": { hand: "rightHandPinkyFinger" },
      "]": { hand: "rightHandPinkyFinger" },
      "\\": { hand: "rightHandPinkyFinger" },
      ";": { hand: "rightHandPinkyFinger" },
      "'": { hand: "rightHandPinkyFinger" },
      ",": { hand: "rightHandMiddleFinger" },
      ".": { hand: "rightHandRingFinger" },
      "/": { hand: "rightHandPinkyFinger" },
};

const english = [
      "as",
      "wrong",
      "his",
      "that",
      "he",
      "was",
      "for",
      "on",
      "are",
      "with",
      "they",
      "be",
      "at",
      "one",
      "have",
      "this",
      "from",
      "by",
      "hot",
      "word",
      "but",
      "what",
      "some",
      "is",
      "it",
      "you",
      "or",
      "had",
      "the",
      "of",
      "to",
      "and",
      "in",
      "we",
      "can",
      "out",
      "other",
      "were",
      "which",
      "do",
      "their",
      "time",
      "if",
      "will",
      "how",
      "said",
      "an",
      "each",
      "tell",
      "does",
      "set",
      "three",
      "want",
      "air",
      "well",
      "also",
      "play",
      "small",
      "end",
      "put",
      "home",
      "read",
      "hand",
      "port",
      "large",
      "spell",
      "add",
      "even",
      "land",
      "here",
      "must",
      "big",
      "high",
      "such",
      "follow",
      "act",
      "why",
      "ask",
      "men",
      "change",
      "went",
      "light",
      "kind",
      "off",
      "need",
      "house",
      "picture",
      "try",
      "us",
      "again",
      "animal",
      "point",
      "mother",
      "world",
      "near",
      "build",
      "self",
      "earth",
      "father",
      "any",
      "new",
      "work",
      "part",
      "take",
      "get",
      "place",
      "made",
      "live",
      "where",
      "after",
      "back",
      "little",
      "only",
      "round",
      "man",
      "year",
      "came",
      "show",
      "every",
      "good",
      "me",
      "give",
      "our",
      "under",
      "name",
      "very",
      "through",
      "just",
      "form",
      "sentence",
      "great",
      "think",
      "say",
      "help",
      "low",
      "line",
      "differ",
      "turn",
      "cause",
      "much",
      "mean",
      "before",
      "move",
      "right",
      "boy",
      "old",
      "too",
      "same",
      "she",
      "all",
      "there",
      "when",
      "up",
      "use",
      "your",
      "way",
      "about",
      "many",
      "then",
      "them",
      "write",
      "would",
      "like",
      "so",
      "these",
      "her",
      "long",
      "make",
      "thing",
      "see",
      "him",
      "two",
      "has",
      "look",
      "more",
      "day",
      "could",
      "go",
      "come",
      "did",
      "number",
      "sound",
      "no",
      "most",
      "people",
      "my",
      "over",
      "know",
      "water",
      "than",
      "call",
      "first",
      "who",
      "may",
      "down",
      "side",
      "been",
      "now",
      "find",
      "head",
      "stand",
      "own",
      "page",
      "should",
      "country",
      "found",
      "answer",
      "school",
      "grow",
      "study",
      "still",
      "learn",
      "plant",
      "cover",
      "food",
      "sun",
      "four",
      "between",
      "state",
      "keep",
      "eye",
      "never",
      "last",
      "let",
      "thought",
      "city",
      "tree",
      "cross",
      "farm",
      "hard",
      "start",
      "might",
      "story",
      "saw",
      "far",
      "sea",
      "draw",
      "left",
      "late",
      "run",
      "dont",
      "while",
      "press",
      "close",
      "night",
      "real",
      "life",
      "few",
      "north",
      "book",
      "carry",
      "took",
      "science",
      "eat",
      "room",
      "friend",
      "began",
      "idea",
      "fish",
      "mountain",
      "stop",
      "once",
      "base",
      "hear",
      "horse",
      "cut",
      "sure",
      "watch",
      "color",
      "face",
      "wood",
      "main",
      "open",
      "seem",
      "together",
      "next",
      "white",
      "children",
      "begin",
      "got",
      "walk",
      "example",
      "ease",
      "paper",
      "group",
      "always",
      "music",
      "those",
      "both",
      "mark",
      "often",
      "letter",
      "until",
      "mile",
      "river",
      "car",
      "feet",
      "care",
      "second",
      "enough",
      //
      "85",
      "70",
      "26",
      "49",
      "31",
      "94",
      "36",
      "58",
      "10",
      "27",
      "182",
      "693",
      "547",
      "476",
      "319",
      "208",
      "706",
      "543",
      "928",
      "134",
      "7036",
      "2518",
      "9485",
      "2173",
      "9460",
      "9134",
      "8072",
      "6580",
      "2693",
      "4715",
      //
      "~",
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "_",
      "+",
      "{",
      "}",
      "|",
      ":",
      '"',
      "?",
      ">",
      "<",
      //
      "-",
      "=",
      "[",
      "]",
      "\\",
      ";",
      "'",
      ",",
      ".",
      "/",
];

const englishOne = {
      as: 1,
      wrong: 1,
      his: 1,
      that: 1,
      he: 1,
      was: 1,
      for: 1,
      on: 1,
      are: 1,
      with: 1,
      they: 1,
      be: 1,
      at: 1,
      one: 1,
      have: 1,
      this: 1,
      from: 1,
      by: 1,
      hot: 1,
      word: 1,
      but: 1,
      what: 1,
      some: 1,
      is: 1,
      it: 1,
      you: 1,
      or: 1,
      had: 1,
      the: 1,
      of: 1,
      to: 1,
      and: 1,
      in: 1,
      we: 1,
      can: 1,
      out: 1,
      other: 1,
      were: 1,
      which: 1,
      do: 1,
      their: 1,
      time: 1,
      if: 1,
      will: 1,
      how: 1,
      said: 1,
      an: 1,
      each: 1,
      tell: 1,
      does: 1,
      set: 1,
      three: 1,
      want: 1,
      air: 1,
      well: 1,
      also: 1,
      play: 1,
      small: 1,
      end: 1,
      put: 1,
      home: 1,
      read: 1,
      hand: 1,
      port: 1,
      large: 1,
      spell: 1,
      add: 1,
      even: 1,
      land: 1,
      here: 1,
      must: 1,
      big: 1,
      high: 1,
      such: 1,
      follow: 1,
      act: 1,
      why: 1,
      ask: 1,
      men: 1,
      change: 1,
      went: 1,
      light: 1,
      kind: 1,
      off: 1,
      need: 1,
      house: 1,
      picture: 1,
      try: 1,
      us: 1,
      again: 1,
      animal: 1,
      point: 1,
      mother: 1,
      world: 1,
      near: 1,
      build: 1,
      self: 1,
      earth: 1,
      father: 1,
      any: 1,
      new: 1,
      work: 1,
      part: 1,
      take: 1,
      get: 1,
      place: 1,
      made: 1,
      live: 1,
      where: 1,
      after: 1,
      back: 1,
      little: 1,
      only: 1,
      round: 1,
      man: 1,
      year: 1,
      came: 1,
      show: 1,
      every: 1,
      good: 1,
      me: 1,
      give: 1,
      our: 1,
      under: 1,
      name: 1,
      very: 1,
      through: 1,
      just: 1,
      form: 1,
      sentence: 1,
      great: 1,
      think: 1,
      say: 1,
      help: 1,
      low: 1,
      line: 1,
      differ: 1,
      turn: 1,
      cause: 1,
      much: 1,
      mean: 1,
      before: 1,
      move: 1,
      right: 1,
      boy: 1,
      old: 1,
      too: 1,
      same: 1,
      she: 1,
      all: 1,
      there: 1,
      when: 1,
      up: 1,
      use: 1,
      your: 1,
      way: 1,
      about: 1,
      many: 1,
      then: 1,
      them: 1,
      write: 1,
      would: 1,
      like: 1,
      so: 1,
      these: 1,
      her: 1,
      long: 1,
      make: 1,
      thing: 1,
      see: 1,
      him: 1,
      two: 1,
      has: 1,
      look: 1,
      more: 1,
      day: 1,
      could: 1,
      go: 1,
      come: 1,
      did: 1,
      number: 1,
      sound: 1,
      no: 1,
      most: 1,
      people: 1,
      my: 1,
      over: 1,
      know: 1,
      water: 1,
      than: 1,
      call: 1,
      first: 1,
      who: 1,
      may: 1,
      down: 1,
      side: 1,
      been: 1,
      now: 1,
      find: 1,
      head: 1,
      stand: 1,
      own: 1,
      page: 1,
      should: 1,
      country: 1,
      found: 1,
      answer: 1,
      school: 1,
      grow: 1,
      study: 1,
      still: 1,
      learn: 1,
      plant: 1,
      cover: 1,
      food: 1,
      sun: 1,
      four: 1,
      between: 1,
      state: 1,
      keep: 1,
      eye: 1,
      never: 1,
      last: 1,
      let: 1,
      thought: 1,
      city: 1,
      tree: 1,
      cross: 1,
      farm: 1,
      hard: 1,
      start: 1,
      might: 1,
      story: 1,
      saw: 1,
      far: 1,
      sea: 1,
      draw: 1,
      left: 1,
      late: 1,
      run: 1,
      dont: 1,
      while: 1,
      press: 1,
      close: 1,
      night: 1,
      real: 1,
      life: 1,
      few: 1,
      north: 1,
      book: 1,
      carry: 1,
      took: 1,
      science: 1,
      eat: 1,
      room: 1,
      friend: 1,
      began: 1,
      idea: 1,
      fish: 1,
      mountain: 1,
      stop: 1,
      once: 1,
      base: 1,
      hear: 1,
      horse: 1,
      cut: 1,
      sure: 1,
      watch: 1,
      color: 1,
      face: 1,
      wood: 1,
      main: 1,
      open: 1,
      seem: 1,
      together: 1,
      next: 1,
      white: 1,
      children: 1,
      begin: 1,
      got: 1,
      walk: 1,
      example: 1,
      ease: 1,
      paper: 1,
      group: 1,
      always: 1,
      music: 1,
      those: 1,
      both: 1,
      mark: 1,
      often: 1,
      letter: 1,
      until: 1,
      mile: 1,
      river: 1,
      car: 1,
      feet: 1,
      care: 1,
      second: 1,
      enough: 1,
      //
      85: 1,
      70: 1,
      26: 1,
      49: 1,
      31: 1,
      94: 1,
      36: 1,
      58: 1,
      10: 1,
      27: 1,
      182: 1,
      693: 1,
      547: 1,
      476: 1,
      319: 1,
      208: 1,
      706: 1,
      543: 1,
      928: 1,
      134: 1,
      7036: 1,
      2518: 1,
      9485: 1,
      2173: 1,
      9460: 1,
      9134: 1,
      8072: 1,
      6580: 1,
      2693: 1,
      4715: 1,
      //
      "~": 1,
      "!": 1,
      "@": 1,
      "#": 1,
      $: 1,
      "%": 1,
      "^": 1,
      "&": 1,
      "*": 1,
      "(": 1,
      ")": 1,
      _: 1,
      "+": 1,
      "{": 1,
      "}": 1,
      "|": 1,
      ":": 1,
      '"': 1,
      "?": 1,
      ">": 1,
      "<": 1,
      //
      "-": 1,
      "=": 1,
      "[": 1,
      "]": 1,
      "\\": 1,
      ";": 1,
      "'": 1,
      ",": 1,
      ".": 1,
      "/": 1,
};
const javascript = [
      "this",
      "function",
      "if",
      "var",
      "return",
      "the",
      "i",
      "a",
      "to",
      "value",
      "else",
      "for",
      "true",
      "length",
      "data",
      "false",
      "name",
      "null",
      "options",
      "is",
      "type",
      "of",
      "param",
      "in",
      "new",
      "element",
      "s",
      "e",
      "event",
      "and",
      "object",
      "prototype",
      "x",
      "jQuery",
      "that",
      "t",
      "key",
      "id",
      "The",
      "string",
      "self",
      "elem",
      "node",
      "on",
      "be",
      "n",
      "b",
      "index",
      "case",
      "d",
      "result",
      "callback",
      "it",
      "undefined",
      "require",
      "y",
      "obj",
      "document",
      "module",
      "from",
      "push",
      "not",
      "or",
      "c",
      "call",
      "typeof",
      "an",
      "_",
      "text",
      "exports",
      "method",
      "width",
      "with",
      "div",
      "use",
      "target",
      "break",
      "get",
      "state",
      "array",
      "set",
      "Object",
      "style",
      "context",
      "scope",
      "error",
      "input",
      "height",
      "arguments",
      "window",
      "p",
      "left",
      "test",
      "val",
      "fn",
      "we",
      "default",
      "o",
      "el",
      "item",
      "number",
      "start",
      "as",
      "path",
      "top",
      "j",
      "class",
      "Math",
      "url",
];

export const languagesAndWords = {
      english: {
            language: "english",
            words: english,
            wordsOne: englishOne,
            options: [
                  {
                        name: "english 1-100",
                        wordsSlices: [[0, 99]],
                  },
                  {
                        name: "english 101-200",
                        wordsSlices: [[100, 199]],
                  },
                  {
                        name: "english 201-300",
                        wordsSlices: [[200, 299]],
                  },
                  {
                        name: "english 1-300",
                        wordsSlices: [[0, 299]],
                  },
                  {
                        name: "english (1-60 words + all numbers(1-30))",
                        wordsSlices: [
                              [0, 59],
                              [300, 329],
                        ],
                  },
                  {
                        name: "english (all numbers(1-30))",
                        wordsSlices: [[300, 329]],
                  },
                  {
                        name: "english (1-60 words + all special characters(1-31))",
                        wordsSlices: [
                              [0, 59],
                              [330, 360],
                        ],
                  },
                  {
                        name: "english (1-300 words + all special characters(1-31))",
                        wordsSlices: [
                              [0, 299],
                              [330, 360],
                        ],
                  },
                  {
                        name: "english (1-300 words + all numbers(1-30))",
                        wordsSlices: [
                              [0, 299],
                              [300, 329],
                        ],
                  },
                  {
                        name: "english (1-300 words + all numbers(1-30) + all special characters(1-31))",
                        wordsSlices: [
                              [0, 299],
                              [300, 329],
                              [330, 360],
                        ],
                  },
            ],
      },
      javascript: {
            language: "javascript",
            words: javascript,
            options: [
                  {
                        name: "javascript 1-100",
                        wordsSlices: [[0, 99]],
                  },
                  {
                        name: "javascript 101-200",
                        wordsSlices: [[100, 199]],
                  },
                  {
                        name: "javascript 201-300",
                        wordsSlices: [[200, 299]],
                  },
            ],
      },
};

export const calculateAverageAccuracy = (
      currentAccuracy,
      finishedTestsCount,
      toBeAddedAccuracy
) => {
      return (
            (currentAccuracy * finishedTestsCount + toBeAddedAccuracy) /
            (finishedTestsCount + 1)
      );
};

export const calculateAverageWpm = (
      currentWpm,
      finishedTestsCount,
      toBeAddedWpm
) => {
      return (
            (currentWpm * finishedTestsCount + toBeAddedWpm) /
            (finishedTestsCount + 1)
      );
};
