import { useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import styles from "./googleAuth.module.css";
import { toast } from "react-toastify";
import { colorRingOptions, toastOptions } from "../../utilities/utilities";
import { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useFetcher } from "react-router-dom";
import { useEffect } from "react";
export const GoogleAuth = () => {
      const [showLoader, setShowLoader] = useState(false);
      const googleSignInFetcher = useFetcher();

      const googleSignInFetcherStatus =
            googleSignInFetcher.state === "idle" && googleSignInFetcher.data;

      useEffect(() => {
            if (googleSignInFetcherStatus) {
                  const data = googleSignInFetcher.data;

                  if (data.status === "success") {
                        window.location = "/";
                  } else {
                        toast.error(data.message, toastOptions);
                  }
                  setShowLoader(false);
            } else if (googleSignInFetcher.state !== "idle") {
                  setShowLoader(true);
            }
      }, [googleSignInFetcher]);
      return (
            <>
                  <GoogleLogin
                        onSuccess={async (credentialResponse) => {
                              googleSignInFetcher.submit(credentialResponse, {
                                    method: "POST",
                                    encType: "application/json",
                                    action: "/login/googleAuth",
                              });
                        }}
                        onError={() => {
                              toast.error("login failed", toastOptions);
                        }}
                        shape="pill"
                        width={window.innerWidth <= 700 ? "70px" : "100px"}
                        size={window.innerWidth <= 700 ? "small" : "medium"}
                        text="signin"
                        theme="filled_black"
                  />
                  {showLoader ? (
                        <div
                              style={{
                                    position: "fixed",

                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "rgba(0,0,0,0.5)",
                                    bottom: "0",
                                    left: "0",
                                    right: "0",
                                    top: "0",
                              }}
                        >
                              <ColorRing {...colorRingOptions}></ColorRing>
                        </div>
                  ) : null}
            </>
      );
};
