import { CardOne } from "../../components/cards/cardOne";
import { useState, useMemo } from "react";
import { useAsyncValue } from "react-router-dom";
import { NavBar } from "../../components/navBar/navBar";
import { TypingArea } from "./typingArea";
import { QuickSettings } from "./quickSettings";
import soundA from "../../assets/sounds/a.mp3";
import soundB from "../../assets/sounds/b.mp3";
import soundC from "../../assets/sounds/f.mp3";
import { Howl } from "howler";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PracticeWord } from "./practiseWord";
import { LanguageStats } from "./languageStats";
import { LastTenTests } from "./lastTenTests";
import { ErrorPage } from "../../components/error/errorPage";
import { BeginnerModeChallenges } from "./beginnerMode";
import { BeginnerModeInformation } from "./beginnerModeInformation";
import { defaultSettings } from "../../utilities/utilities";
import { Analytics } from "@vercel/analytics/react";
import styles from "./homePage.module.css";

const sounds = {
      soundA,
      soundB,
      soundC,
};

export const HomePage = () => {
      const settingsLoaderData = useAsyncValue()[1].value;
      const userDetailsLoaderData = useAsyncValue()[0].value;

      const [statsData, setStatsData] = useState(() => {
            if (settingsLoaderData.status === "success") {
                  return settingsLoaderData.payload.stats;
            } else {
                  return {};
            }
      });
      const [beginnerModeStats, setBeginnerModeStats] = useState(() => {
            if (settingsLoaderData.status === "success") {
                  const newData = {
                        lessons: {},
                  };
                  settingsLoaderData.payload.beginnerModeStats.lessons?.forEach(
                        (element) => {
                              newData.lessons[element.lessonId] = element;
                        }
                  );
                  return newData;
            } else {
                  return {};
            }
      });
      const [settings, setSettings] = useState(() => {
            if (settingsLoaderData.status === "success") {
                  return settingsLoaderData.payload.settings;
            } else {
                  return defaultSettings;
            }
      });

      const [allWords, setAllWords] = useState(() => {
            if (settingsLoaderData.status === "success") {
                  return settingsLoaderData.payload.words;
            } else {
                  return [
                        "as",
                        "wrong",
                        "his",
                        "that",
                        "he",
                        "was",
                        "for",
                        "on",
                        "are",
                        "with",
                        "they",
                        "be",
                        "at",
                        "one",
                        "have",
                        "this",
                        "from",
                        "by",
                        "hot",
                        "word",
                        "but",
                        "what",
                        "some",
                        "is",
                        "it",
                        "you",
                        "or",
                        "had",
                        "the",
                        "of",
                        "to",
                        "and",
                        "in",
                        "we",
                        "can",
                        "out",
                        "other",
                        "were",
                        "which",
                        "do",
                        "their",
                        "time",
                        "if",
                        "will",
                        "how",
                        "said",
                        "an",
                        "each",
                        "tell",
                        "does",
                        "set",
                        "three",
                        "want",
                        "air",
                        "well",
                        "also",
                        "play",
                        "small",
                        "end",
                        "put",
                        "home",
                        "read",
                        "hand",
                        "port",
                        "large",
                        "spell",
                        "add",
                        "even",
                        "land",
                        "here",
                        "must",
                        "big",
                        "high",
                        "such",
                        "follow",
                        "act",
                        "why",
                        "ask",
                        "men",
                        "change",
                        "went",
                        "light",
                        "kind",
                        "off",
                        "need",
                        "house",
                        "picture",
                        "try",
                        "us",
                        "again",
                        "animal",
                        "point",
                        "mother",
                        "world",
                        "near",
                        "build",
                        "self",
                        "earth",
                        "father",
                        "any",
                  ];
            }
      });

      const [wordIndex, setWordIndex] = useState(0);
      const [currentLesson, setCurrentLesson] = useState(0);
      const [currentKey, setCurrentKey] = useState(" ");
      const [showLessons, setShowLessons] = useState(false);
      const [showWordsQueue, setShowWordsQueue] = useState(false);
      const [showParagraphLoader, setShowParagraphLoader] = useState(false);
      const typingSound = useMemo(() => {
            if (settings.sound === "none") {
                  return null;
            }
            return new Howl({
                  src: sounds[settings.sound],
                  volume: 0.5,
            });
      }, [settings.sound]);

      const [testStarted, setShowTestStarted] = useState(false);
      const [showLastTenTests, setShowLastTenTests] = useState(false);

      const practiseModeAllWords = useMemo(() => {
            let newWords = [];
            if (
                  statsData.testMode?.wordsStats &&
                  settings.mode === "practise"
            ) {
                  Object.entries(statsData.testMode.wordsStats).forEach(
                        ([key, value]) => {
                              if (
                                    value.averageWpm < +settings.modeThree &&
                                    key !== " "
                              ) {
                                    newWords.push(key);
                              }
                        }
                  );
            } else {
                  newWords = [...allWords];
            }
            return newWords;
      }, [settings.mode, settings["language and range"], settings.modeThree]);

      let showError = false;
      let error = null;
      if (
            userDetailsLoaderData.status === "error" &&
            userDetailsLoaderData.message !== "no token"
      ) {
            showError = true;
            if (userDetailsLoaderData.message === "jwt expired") {
                  error = {
                        message: "session expired,please login again",
                  };
            } else {
                  error = { message: userDetailsLoaderData.message };
            }
      } else if (
            settingsLoaderData.status === "error" &&
            settingsLoaderData.message !== "no token"
      ) {
            showError = true;
            if (settingsLoaderData.message === "jwt expired") {
                  error = {
                        message: "session expired,please login again",
                  };
            } else {
                  error = { message: settingsLoaderData.message };
            }
      }

      if (showError) {
            return <ErrorPage error={error}></ErrorPage>;
      }

      return (
            <CardOne
                  attributes={{ className: styles["page"], tabIndex: -1 }}
                  theme={settings.theme}
            >
                  <Analytics></Analytics>
                  <ToastContainer />
                  <NavBar
                        theme={settings.theme}
                        userDetails={userDetailsLoaderData.payload}
                  ></NavBar>
                  <main className={styles["main"]}>
                        {settings.mode === "beginner" ? (
                              <BeginnerModeInformation
                                    settings={settings}
                                    setShowLessons={setShowLessons}
                                    currentKey={currentKey}
                                    beginnerModeStats={beginnerModeStats}
                              ></BeginnerModeInformation>
                        ) : null}
                        {settings.mode === "practise" ? (
                              <section
                                    className={styles["practise-word-section"]}
                              >
                                    <PracticeWord
                                          key={
                                                settings.mode +
                                                settings.modeOne +
                                                settings.modeTwo +
                                                settings.modeThree +
                                                settings["language and range"]
                                                      .language +
                                                settings["language and range"]
                                                      .optionIndex
                                          }
                                          settings={settings}
                                          statsData={statsData}
                                          wordIndex={wordIndex}
                                          allWords={practiseModeAllWords}
                                          serialNumber={wordIndex + 1}
                                          setShowWordsQueue={setShowWordsQueue}
                                          isWordsQueue={true}
                                          testStarted={testStarted}
                                    ></PracticeWord>
                              </section>
                        ) : null}

                        {settings.mode === "test" ? (
                              <section
                                    className={styles["language-stats-section"]}
                              >
                                    <LanguageStats
                                          key={
                                                settings.mode +
                                                settings.modeOne +
                                                settings.modeTwo +
                                                settings.modeThree +
                                                settings["language and range"]
                                                      .language +
                                                settings["language and range"]
                                                      .optionIndex
                                          }
                                          statsData={statsData}
                                          testStarted={testStarted}
                                          setShowLastTenTests={
                                                setShowLastTenTests
                                          }
                                          settings={settings}
                                    ></LanguageStats>
                              </section>
                        ) : null}

                        <section className={styles["typing-section"]}>
                              <TypingArea
                                    settings={settings}
                                    typingSound={typingSound}
                                    allWords={allWords}
                                    setWordIndex={setWordIndex}
                                    wordIndex={wordIndex}
                                    setCurrentKey={setCurrentKey}
                                    key={
                                          settings.mode +
                                          settings.modeOne +
                                          settings.modeTwo +
                                          settings.modeThree +
                                          settings["language and range"]
                                                .language +
                                          settings["language and range"]
                                                .optionIndex +
                                          wordIndex +
                                          settings.timer +
                                          settings.modeFour
                                    }
                                    statsData={statsData}
                                    showParagraphLoader={showParagraphLoader}
                                    practiseModeAllWords={practiseModeAllWords}
                                    setStatsData={setStatsData}
                                    showWordsQueue={showWordsQueue}
                                    setShowTestStarted={setShowTestStarted}
                                    setShowWordsQueue={setShowWordsQueue}
                                    userDetails={userDetailsLoaderData.payload}
                                    setSettings={setSettings}
                                    setBeginnerModeStats={setBeginnerModeStats}
                              ></TypingArea>
                        </section>
                        <QuickSettings
                              settings={settings}
                              setSettings={setSettings}
                              setAllWords={setAllWords}
                              allWords={allWords}
                              setWordIndex={setWordIndex}
                              statsData={statsData}
                              setShowParagraphLoader={setShowParagraphLoader}
                              setStatsData={setStatsData}
                              key={
                                    settings.mode +
                                    settings.modeOne +
                                    settings.modeTwo +
                                    settings.modeThree +
                                    settings["language and range"].language +
                                    settings["language and range"].optionIndex
                              }
                              testStarted={testStarted}
                              userDetails={userDetailsLoaderData.payload}
                        ></QuickSettings>
                        {showLastTenTests ? (
                              <LastTenTests
                                    statsData={statsData}
                                    settings={settings}
                                    setShowLastTenTests={setShowLastTenTests}
                              ></LastTenTests>
                        ) : null}

                        {showLessons ? (
                              <BeginnerModeChallenges
                                    theme={settings.theme}
                                    setShowLessons={setShowLessons}
                                    setSettings={setSettings}
                                    beginnerModeStats={beginnerModeStats}
                              ></BeginnerModeChallenges>
                        ) : null}
                  </main>
            </CardOne>
      );
};
