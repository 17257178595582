import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClosingButton } from "../buttons/closingButton";
import styles from "./exportImport.module.css";
import { faDownload, faFile } from "@fortawesome/free-solid-svg-icons";
import { defaultSettings } from "../../utilities/utilities";

export const ExportImport = ({ setShowExportImport, theme }) => {
      const JSONToFile = (obj, filename) => {
            const blob = new Blob([JSON.stringify(obj, null, 2)], {
                  type: "application/json",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${filename}.json`;
            a.click();
            URL.revokeObjectURL(url);
      };

      const saveJsonToFile = () => {
            const settings = JSON.parse(localStorage.getItem("settings"));
            const languagesStats = JSON.parse(
                  localStorage.getItem("languagesStats")
            );
            const beginnerModeStats = JSON.parse(
                  localStorage.getItem("beginnerModeStats")
            );

            JSONToFile(
                  {
                        settings,
                        languagesStats,
                        beginnerModeStats,
                  },
                  "minitypeData"
            );
      };

      const readUploadedJsonFile = (event) => {
            var reader = new FileReader();

            reader.onload = function (event) {
                  var jsonObj = JSON.parse(event.target.result);
                  console.log(jsonObj);
                  if (jsonObj.settings) {
                        localStorage.setItem(
                              "settings",
                              JSON.stringify(jsonObj.settings)
                        );
                  }

                  if (jsonObj.languagesStats) {
                        localStorage.setItem(
                              "languagesStats",
                              JSON.stringify(jsonObj.languagesStats)
                        );
                  }
                  if (jsonObj.beginnerModeStats) {
                        localStorage.setItem(
                              "beginnerModeStats",
                              JSON.stringify(jsonObj.beginnerModeStats)
                        );
                  }
                  window.location = "/";
            };

            reader.readAsText(event.target.files[0]);
      };

      return (
            <section className={styles["data-saving-uploading-section"]}>
                  <ClosingButton
                        clickHandler={() => {
                              setShowExportImport(false);
                        }}
                  ></ClosingButton>
                  <input
                        className={styles["file-input"]}
                        id="file"
                        type="file"
                        placeholder="import"
                        onChange={readUploadedJsonFile}
                        accept=".json"
                  />
                  <label
                        for="file"
                        className={
                              styles["upload-label"] +
                              " " +
                              styles["upload-label-" + theme]
                        }
                  >
                        <FontAwesomeIcon icon={faFile}></FontAwesomeIcon>
                        Upload file
                  </label>
                  <button
                        className={
                              styles["download-button"] +
                              " " +
                              styles["upload-label-" + theme]
                        }
                        onClick={saveJsonToFile}
                  >
                        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                        Download file
                  </button>
            </section>
      );
};
