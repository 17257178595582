import { redirect } from "react-router-dom";
import {
      updateUserSettings as a,
      postBeginnerModeStats as b,
      postUserLanguageSubTypeStats as c,
} from "./localStorageActions";

let backEndUrl = "http://localhost:8080/";
backEndUrl = "https://typing-web-app-zeta.vercel.app/";

export const postTestStats = async (testStats) => {
      try {
            // const response = await fetch(`${backEndUrl}userStats`, {
            //       headers: {
            //             "Content-Type": "application/json",
            //       },
            //       credentials: "include",
            //       method: "POST",
            //       body: JSON.stringify(testStats),
            // });
            // const data = await response.json();
            const data = await c({ body: testStats });
            return data;
      } catch (error) {
            return {
                  status: "error",
                  message: error.message,
            };
      }
};

export const postBeginnerModeStats = async (testStats) => {
      try {
            // const response = await fetch(`${backEndUrl}beginnerModeStats`, {
            //       headers: {
            //             "Content-Type": "application/json",
            //       },
            //       credentials: "include",
            //       method: "POST",
            //       body: JSON.stringify(testStats),
            // });
            // const data = await response.json();
            const data = await b({ body: testStats });
            return data;
      } catch (error) {
            return {
                  status: "error",
                  message: error.message,
            };
      }
};
export const updateSettings = async (body) => {
      try {
            // const response = await fetch(`${backEndUrl}settings`, {
            //       headers: {
            //             "Content-Type": "application/json",
            //       },
            //       credentials: "include",
            //       method: "PUT",
            //       body: JSON.stringify(body),
            // });
            // const data = await response.json();
            const data = await a({ body: body });
            return data;
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const registerAction = async ({ request, params }) => {
      try {
            const formData = await request.formData();
            const body = Object.fromEntries(formData);
            const response = await fetch(`${backEndUrl}register`, {
                  headers: {
                        "Content-Type": "application/json",
                  },
                  credentials: "include",
                  method: "POST",
                  body: JSON.stringify(body),
            });
            const data = await response.json();
            return data;
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const loginAction = async ({ request, params }) => {
      try {
            const formData = await request.formData();
            const body = Object.fromEntries(formData);
            const response = await fetch(`${backEndUrl}login`, {
                  headers: {
                        "Content-Type": "application/json",
                  },
                  method: "POST",
                  credentials: "include",
                  body: JSON.stringify(body),
            });
            const data = await response.json();
            return data;
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const googleSignIn = async ({ request, params }) => {
      try {
            const body = await request.json();

            console.log(body);
            const response = await fetch(`${backEndUrl}login/googleAuth`, {
                  method: "POST",
                  headers: {
                        "Content-Type": "application/json",
                  },
                  credentials: "include",
                  body: JSON.stringify(body),
            });
            const data = await response.json();
            return data;
      } catch (error) {
            return { status: "error", message: error.message };
      }
};

export const logoutAction = () => {
      localStorage.removeItem("token");
      return redirect("/");
};

export const logoutUser = async () => {
      try {
            const response = await fetch(`${backEndUrl}logout`, {
                  credentials: "include",
                  method: "POST",
            });
            const data = await response.json();
            return data;
      } catch (error) {
            return { status: "error", message: error.message };
      }
};
