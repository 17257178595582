import styles from "./lastTenTests.module.css";
import { ClosingButton } from "../../components/buttons/closingButton";
export const LastTenTests = ({ statsData, settings, setShowLastTenTests }) => {
      const closingButtonClickHandler = () => {
            setShowLastTenTests(false);
      };
      return (
            <section className={styles["last-ten-tests-section"]}>
                  <ClosingButton
                        clickHandler={closingButtonClickHandler}
                  ></ClosingButton>
                  <h2>Last ten tests</h2>
                  <table
                        className={
                              styles["last-ten-tests-table"] +
                              " " +
                              styles[settings.theme]
                        }
                  >
                        <tbody>
                              <tr>
                                    <th className={styles["th"]}>s.no</th>
                                    <th className={styles["th"]}>speed</th>
                                    <th className={styles["th"]}>Accuracy</th>
                                    {/* <th className={styles["th"]}>Time</th> */}
                                    <th className={styles["th"]}>Date</th>
                              </tr>
                        </tbody>
                        {statsData?.testMode?.timerBasedStats[
                              settings.timer
                        ]?.lastTwentyTests
                              .slice(-10)
                              .toReversed()
                              .map((test, index) => {
                                    return (
                                          <tbody key={index}>
                                                <tr>
                                                      <td
                                                            className={
                                                                  styles["td"]
                                                            }
                                                      >
                                                            {index + 1}
                                                      </td>
                                                      <td
                                                            className={
                                                                  styles["td"]
                                                            }
                                                      >
                                                            {test.wpm} wpm
                                                      </td>
                                                      <td
                                                            className={
                                                                  styles["td"]
                                                            }
                                                      >
                                                            {test.accuracy} %
                                                      </td>
                                                      {/* <td
                                                            className={
                                                                  styles["td"]
                                                            }
                                                      >
                                                            {test.timer} s
                                                      </td> */}
                                                      <td
                                                            className={
                                                                  styles["td"]
                                                            }
                                                      >
                                                            {(() => {
                                                                  let date =
                                                                        new Date(
                                                                              test.date
                                                                        );
                                                                  return date.toLocaleString();
                                                            })()}
                                                      </td>
                                                </tr>
                                          </tbody>
                                    );
                              })}
                  </table>
            </section>
      );
};
