import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useFetcher } from "react-router-dom";
import styles from "./typingParagraph.module.css";
import { typingParagraphReducer } from "../../reducers/typingParagraphReducer";
import { postTestStats, postBeginnerModeStats } from "../../actions/actions";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
      faRotate,
      faForward,
      faBackward,
} from "@fortawesome/free-solid-svg-icons";
import { ColorRing } from "react-loader-spinner";
import { colorRingOptions, toastOptions } from "../../utilities/utilities";
import { WordsQueue } from "./wordsQueue";
import { NavItem } from "../../components/navBar/navItem";
import { beginnerModeData } from "../../utilities/utilities";

import {
      updateCharactersStats,
      updateWpmAndAccuracy,
      createtypingParagraphJsx,
      initialTypingState,
      wordsMixerOne,
} from "../../utilities/utilities";
import { getBeginnerModeStats } from "../../loaders/loaders";

export const TypingArea = (props) => {
      const [allWords, setAllWords] = useState(props.allWords);

      const [showParagraphFocusOverlay, setShowParagraphFocusOverlay] =
            useState(false);
      const [restart, setRestart] = useState({});
      const [timerState, setTimerState] = useState({
            elapsedTime: 0,
            timerId: undefined,
      });

      //   const [showParagraphLoader, setShowParagraphLoader] = useState(false);
      const words = useMemo(() => {
            return wordsMixerOne(props);
      }, [restart]);

      const letters = useMemo(() => {
            const letters = [];

            for (let i = 0; i < words.length; i++) {
                  letters.push(...words[i]);
            }
            return letters;
      }, [words]);

      useEffect(() => {
            props.setCurrentKey(" ");
      }, [letters]);

      useEffect(() => {
            if (timerState.timerId !== undefined) {
                  if (words.length - typingState.currentWordIndex + 1 === 5) {
                        const newWords = wordsMixerOne(props);

                        for (let j = 1; j < newWords.length; j++) {
                              words.push(newWords[j]);
                        }

                        // const newLetters = [];

                        for (let i = 1; i < newWords.length; i++) {
                              letters.push(...newWords[i]);
                        }

                        // for (let i = 0; i < newLetters.length; i++) {
                        //       letters.push(newLetters[i]);
                        // }
                  }
            }
      });

      const [typingState, dispatch] = useReducer(
            typingParagraphReducer,
            initialTypingState
      );

      const testStats = useMemo(() => {
            const newDate = new Date();
            return {
                  languageAndRange: props.settings["language and range"],
                  mode: props.settings.mode,
                  lesson: beginnerModeData[props.settings.modeFour].lessonId,
                  totalNumberOfRightHits: 0,
                  totalNumberOfWrongHits: 0,
                  wpm: 0,
                  accuracy: 0,
                  charactersStats: {},
                  wordsStats: {},
                  date: newDate.toISOString(),
                  timer: +props.settings.timer,
            };
      }, [restart]);

      if (
            timerState.elapsedTime === +props.settings.timer &&
            !typingState.finished
      ) {
            clearInterval(timerState.timerId);
            updateWpmAndAccuracy(timerState, testStats);

            dispatch({ type: "finished test" });
            props.setShowTestStarted(false);
      }

      const keyDownHandler = (event) => {
            if (!props.typingSound?.playing()) {
                  props.typingSound?.play();
            } else {
                  props.typingSound?.stop();
                  props.typingSound?.play();
            }

            if (
                  !typingState.finished &&
                  event.key !== "Tab" &&
                  event.key !== "Shift"
            ) {
                  if (event.key === letters[typingState.paragraphNextIndex]) {
                        dispatch({
                              type: "right hit",
                              payload: {
                                    words,
                                    testStats,
                              },
                        });
                        if (typingState.currentWordIndex > 0) {
                              updateCharactersStats(
                                    { type: "right hit" },
                                    testStats,
                                    letters[typingState.paragraphNextIndex],
                                    timerState
                              );
                        }
                        if (props.settings.mode === "beginner") {
                              props.setCurrentKey(
                                    letters[typingState.paragraphNextIndex + 1]
                              );
                        }
                  } else {
                        dispatch({
                              type: "wrong hit",
                              payload: {
                                    words,
                                    testStats,
                              },
                        });
                        if (typingState.currentWordIndex > 0) {
                              updateCharactersStats(
                                    { type: "wrong hit" },
                                    testStats,
                                    letters[typingState.paragraphNextIndex],
                                    timerState
                              );
                        }
                  }

                  if (timerState.timerId === undefined) {
                        const timerId = setInterval(() => {
                              setTimerState((previous) => {
                                    props.setShowTestStarted(true);
                                    return {
                                          ...previous,
                                          elapsedTime: previous.elapsedTime + 1,
                                    };
                              });
                        }, 1000);
                        setTimerState((previous) => {
                              return { ...previous, timerId };
                        });
                  }
            }
      };

      const typingParagraphRef = useRef();
      const wordRef = useRef(null);

      const paragraph = createtypingParagraphJsx(
            words,
            typingState,
            wordRef,
            props.settings.theme,
            props.settings.textEffect
      );

      const restartHandler = (event) => {
            if (props.settings.mode === "practise") {
                  if (
                        props.wordIndex ===
                        props.practiseModeAllWords.length - 1
                  ) {
                        props.setWordIndex(0);
                  } else {
                        props.setWordIndex((previous) => {
                              return previous + 1;
                        });
                  }
            } else if (props.settings.mode === "beginner") {
                  props.setSettings((previous) => {
                        return {
                              ...previous,
                              modeFour: Math.min(
                                    beginnerModeData.length - 1,
                                    +previous.modeFour + 1
                              ),
                        };
                  });
            }

            dispatch({ type: "reset" });
            setRestart({});
            props.setShowTestStarted(false);
            clearInterval(timerState.timerId);
            setTimerState({
                  elapsedTime: 0,
                  timerId: undefined,
            });
            typingParagraphRef.current.focus();
            typingParagraphRef.current.scrollTo(0, 0);
            event.target.blur();
      };

      const reloadWordPractise = (event) => {
            dispatch({ type: "reset" });
            setRestart({});
            props.setShowTestStarted(false);
            clearInterval(timerState.timerId);
            setTimerState({
                  elapsedTime: 0,
                  timerId: undefined,
            });
            typingParagraphRef.current.focus();
            event.target.blur();
      };

      const reloadWordPractiseKeyDownHandler = (event) => {
            if (event.key === "Enter") {
                  event.target.click();
            }
      };
      const goBackButtonClickHandler = (event) => {
            if (props.settings.mode === "beginner") {
                  props.setSettings((previous) => {
                        return {
                              ...previous,
                              modeFour: Math.max(0, +previous.modeFour - 1),
                        };
                  });
            } else if (props.settings.mode === "practise") {
                  if (props.wordIndex === 0) {
                        return;
                  } else {
                        props.setWordIndex((previous) => {
                              return previous - 1;
                        });
                  }
            }

            typingParagraphRef.current.focus();
            event.target.blur();

            dispatch({ type: "reset" });
            setRestart({});
            props.setShowTestStarted(false);
            clearInterval(timerState.timerId);
            setTimerState({
                  elapsedTime: 0,
                  timerId: undefined,
            });
      };

      const focusHandler = (event) => {
            event.target.style.border = "3px solid white";
      };

      const restartKeyDownHandler = (event) => {
            if (event.key === "Enter") {
                  event.target.click();
            }
      };
      const goBackButtonKeyDownHandler = (event) => {
            if (event.key === "Enter") {
                  event.target.click();
            }
      };

      const restartOnBlurHandler = (event) => {
            event.target.style.border = "none";
      };

      useEffect(() => {
            if (
                  timerState.timerId &&
                  wordRef.current &&
                  (wordRef.current.offsetTop >= 120 ||
                        wordRef.current.offsetTop % 40 === 0)
            ) {
                  wordRef.current.scrollIntoView(false);
            }
      }, [wordRef.current]);

      useEffect(() => {
            typingParagraphRef.current.focus();
      }, []);

      const statsFetcher = useFetcher();
      const beginnerModeStatsFetcher = useFetcher();

      const beginnerModeStatsFetcherStatus =
            beginnerModeStatsFetcher.data &&
            beginnerModeStatsFetcher.state === "idle";

      const statsFetcherStatus =
            statsFetcher.data && statsFetcher.state === "idle";

      useEffect(() => {
            if (beginnerModeStatsFetcherStatus) {
                  const data = beginnerModeStatsFetcher.data;

                  if (data.status === "success") {
                        toast.success("yes", toastOptions);
                  } else {
                        toast.error(data.message, toastOptions);
                  }
            }
      }, [beginnerModeStatsFetcher]);

      useEffect(() => {
            if (statsFetcherStatus) {
                  const data = statsFetcher.data;

                  if (data.status === "success") {
                        props.setStatsData(data.payload);
                  } else {
                        toast.error(data.message, toastOptions);
                  }
            }
      }, [statsFetcher]);
      useEffect(() => {
            if (typingState.finished && props.userDetails) {
                  const apiOne = async () => {
                        const data = await postTestStats(testStats);
                        if (data.status === "success") {
                              statsFetcher.submit(
                                    props.settings["language and range"],
                                    {
                                          action: "/stats",
                                          method: "GET",
                                    }
                              );
                        } else {
                              toast.error(data.message, toastOptions);
                        }
                  };
                  const apiTwo = async () => {
                        const data = await postBeginnerModeStats(testStats);
                        if (data.status === "success") {
                              //   beginnerModeStatsFetcher.submit({
                              //         action: "/beginnerModeStats",
                              //         method: "GET",
                              //   });
                              const dataOne = await getBeginnerModeStats();
                              if (dataOne.status === "success") {
                                    props.setBeginnerModeStats(dataOne.payload);
                              } else {
                                    toast.error(dataOne.message, toastOptions);
                              }
                        } else {
                              toast.error(data.message, toastOptions);
                        }
                  };

                  if (testStats.wordsStats.hasOwnProperty(" ")) {
                        delete testStats.wordsStats[" "];
                  }
                  if (testStats.mode !== "beginner") {
                        apiOne();
                  } else {
                        apiTwo();
                  }
            }
      }, [typingState.finished]);

      const focusableElements = useMemo(() => {
            return { elements: null, index: -1 };
      }, []);

      useEffect(() => {
            focusableElements.elements = document.querySelectorAll(
                  `.${styles["controls"]} [tabindex='0']`
            );

            const callBack = (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (event.key === "Tab") {
                        if (
                              focusableElements.index ===
                              focusableElements.elements.length - 1
                        ) {
                              focusableElements.index = 0;
                        } else {
                              focusableElements.index++;
                        }

                        focusableElements.elements[
                              focusableElements.index
                        ].focus();
                  } else if (event.key === "Enter") {
                        focusableElements.index = -1;
                  }
            };
            window.addEventListener("keydown", callBack);

            return () => {
                  window.removeEventListener("keydown", callBack);
            };
      }, []);

      return (
            <>
                  {/* {typingState.finished ? (
                        <TestStats
                              testStats={testStats}
                              theme={props.theme}
                        ></TestStats>
                  ) : null} */}
                  <div className={styles["test-stats"]}>
                        <div className={styles["test-stat"]}>
                              {timerState.elapsedTime}
                        </div>
                        {!typingState.finished ? (
                              ""
                        ) : (
                              <div className={styles["test-stat"]}>
                                    speed:{" "}
                                    {props.settings.mode === "practise"
                                          ? Math.round(
                                                  testStats.wordsStats[
                                                        props
                                                              .practiseModeAllWords[
                                                              props.wordIndex
                                                        ]
                                                  ].wpm
                                            )
                                          : testStats.wpm}
                                    wpm
                              </div>
                        )}
                        {!typingState.finished ? (
                              ""
                        ) : (
                              <div className={styles["test-stat"]}>
                                    accuracy: {testStats.accuracy}%
                              </div>
                        )}

                        <div className={styles["controls"]}>
                              {props.settings.mode === "practise" ||
                              props.settings.mode === "beginner" ? (
                                    <>
                                          <div
                                                className={
                                                      styles[
                                                            "load-next-paragraph-form"
                                                      ]
                                                }
                                          >
                                                <NavItem
                                                      text={"previous"}
                                                      theme={
                                                            props.settings.theme
                                                      }
                                                >
                                                      <button
                                                            aria-label="previous"
                                                            tabIndex={0}
                                                            type="submit"
                                                            className={
                                                                  styles[
                                                                        "load-next-paragraph-button"
                                                                  ] +
                                                                  " " +
                                                                  styles[
                                                                        `icon-${props.settings.theme}`
                                                                  ]
                                                            }
                                                            onFocus={
                                                                  focusHandler
                                                            }
                                                            onBlur={
                                                                  restartOnBlurHandler
                                                            }
                                                            onKeyDown={
                                                                  goBackButtonKeyDownHandler
                                                            }
                                                            onClick={
                                                                  goBackButtonClickHandler
                                                            }
                                                      >
                                                            <FontAwesomeIcon
                                                                  icon={
                                                                        faBackward
                                                                  }
                                                            />
                                                      </button>
                                                </NavItem>
                                          </div>

                                          <div
                                                className={
                                                      styles[
                                                            "load-next-paragraph-form"
                                                      ]
                                                }
                                          >
                                                <NavItem
                                                      text={"reload"}
                                                      theme={
                                                            props.settings.theme
                                                      }
                                                >
                                                      <button
                                                            aria-label="reload"
                                                            tabIndex={0}
                                                            className={
                                                                  styles[
                                                                        "load-next-paragraph-button"
                                                                  ] +
                                                                  " " +
                                                                  styles[
                                                                        `icon-${props.settings.theme}`
                                                                  ]
                                                            }
                                                            onClick={
                                                                  reloadWordPractise
                                                            }
                                                            onFocus={
                                                                  focusHandler
                                                            }
                                                            onKeyDown={
                                                                  reloadWordPractiseKeyDownHandler
                                                            }
                                                            onBlur={
                                                                  restartOnBlurHandler
                                                            }
                                                      >
                                                            <FontAwesomeIcon
                                                                  icon={
                                                                        faRotate
                                                                  }
                                                            />
                                                      </button>
                                                </NavItem>
                                          </div>
                                    </>
                              ) : null}

                              <div
                                    className={
                                          styles["load-next-paragraph-form"]
                                    }
                              >
                                    <NavItem
                                          text={"next"}
                                          theme={props.settings.theme}
                                    >
                                          <button
                                                aria-label="next"
                                                tabIndex={0}
                                                type="submit"
                                                className={
                                                      styles[
                                                            "load-next-paragraph-button"
                                                      ] +
                                                      " " +
                                                      styles[
                                                            `icon-${props.settings.theme}`
                                                      ]
                                                }
                                                onFocus={focusHandler}
                                                onKeyDown={
                                                      restartKeyDownHandler
                                                }
                                                onBlur={restartOnBlurHandler}
                                                onClick={restartHandler}
                                          >
                                                <FontAwesomeIcon
                                                      icon={faForward}
                                                />
                                          </button>
                                    </NavItem>
                              </div>
                        </div>
                  </div>

                  <div
                        className={styles["typing-paragraph"]}
                        onKeyDown={keyDownHandler}
                        onBlur={() => {
                              setShowParagraphFocusOverlay(true);
                        }}
                        onFocus={(event) => {
                              event.stopPropagation();
                              setShowParagraphFocusOverlay(false);
                        }}
                        ref={typingParagraphRef}
                        tabIndex={-1}
                  >
                        {paragraph}
                        {props.showParagraphLoader ? (
                              <div className={styles["paragraph-loader"]}>
                                    <ColorRing {...colorRingOptions} />
                              </div>
                        ) : null}

                        {showParagraphFocusOverlay ? (
                              props.showParagraphLoader ? null : (
                                    <div
                                          className={
                                                styles[
                                                      "paragraph-on-blur-overlay"
                                                ]
                                          }
                                          onFocus={(event) => {
                                                event.stopPropagation();
                                          }}
                                    >
                                          click here to focus , press tab to
                                          navigate through buttons and enter to
                                          select and focus here
                                    </div>
                              )
                        ) : null}
                  </div>
                  {props.settings.mode === "practise" &&
                  props.showWordsQueue ? (
                        <WordsQueue
                              words={props.practiseModeAllWords}
                              statsData={props.statsData}
                              settings={props.settings}
                              setWordIndex={props.setWordIndex}
                              setShowWordsQueue={props.setShowWordsQueue}
                        ></WordsQueue>
                  ) : null}
            </>
      );
};
