import { GuidePage } from "./guidePage";
import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { colorRingOptions } from "../../utilities/utilities";
import { ColorRing } from "react-loader-spinner";
import styles from "./awaitGuidePage.module.css";
export const AwaitGuidePage = () => {
      const { loader } = useLoaderData();

      return (
            <Suspense
                  fallback={
                        <div className={styles["page"]}>
                              <ColorRing {...colorRingOptions} />
                        </div>
                  }
            >
                  <Await resolve={loader}>
                        <GuidePage></GuidePage>
                  </Await>
            </Suspense>
      );
};
