import styles from "./languageStats.module.css";
import { lastTwentyTestsAverages } from "../../utilities/utilities";
export const LanguageStats = ({
      statsData,
      classOne,
      testStarted,
      setShowLastTenTests,
      settings,
}) => {
      let testMode = statsData?.testMode?.timerBasedStats[settings.timer];

      if (testMode) {
            [
                  testMode.lastTwentyTestsAverageWpm,
                  testMode.lastTwentyTestsAverageAccuracy,
            ] = lastTwentyTestsAverages(testMode.lastTwentyTests);
      }

      return (
            <div
                  className={
                        styles["word"] + " " + styles["word-" + settings.theme]
                  }
                  style={testStarted ? { opacity: 0 } : null}
            >
                  <div className={styles["word-title"]}>
                        {settings["language and range"].fullName}
                        <button
                              className={styles["last-ten-test-open-button"]}
                              onClick={(event) => {
                                    event.stopPropagation();
                                    setShowLastTenTests((previous) => {
                                          return !previous;
                                    });
                              }}
                        >
                              last 10 tests
                        </button>
                  </div>
                  <div
                        className={
                              classOne
                                    ? styles["word-stats"] + " " + classOne
                                    : styles["word-stats"]
                        }
                  >
                        <table className={styles["table"]}>
                              {/* <tr>
                                    <th colspan="3">Test mode</th>
                              </tr> */}
                              <tbody>
                                    <tr>
                                          <th className={styles["th"]}>
                                                all tests averages
                                          </th>
                                          {/* <th>all tests avg accuracy</th> */}
                                          <th className={styles["th"]}>
                                                last 20 tests averages
                                          </th>
                                          {/* <th>last 20 tests avg accuracy</th> */}
                                          <th className={styles["th"]}>
                                                highest speed of a test
                                          </th>
                                          <th className={styles["th"]}>
                                                total tests
                                          </th>
                                    </tr>
                              </tbody>
                              <tbody>
                                    <tr>
                                          <td className={styles["td"]}>
                                                {testMode ? (
                                                      <>
                                                            {Math.round(
                                                                  testMode.averageWpm
                                                            )}{" "}
                                                            wpm /{" "}
                                                            {Math.round(
                                                                  testMode.averageAccuracy
                                                            )}{" "}
                                                            %
                                                      </>
                                                ) : (
                                                      "not attempted"
                                                )}
                                          </td>

                                          <td className={styles["td"]}>
                                                {testMode ? (
                                                      <>
                                                            {Math.round(
                                                                  testMode.lastTwentyTestsAverageWpm
                                                            )}{" "}
                                                            wpm /{" "}
                                                            {Math.round(
                                                                  testMode.lastTwentyTestsAverageAccuracy
                                                            )}{" "}
                                                            %
                                                      </>
                                                ) : (
                                                      "not attempted"
                                                )}
                                          </td>

                                          <td className={styles["td"]}>
                                                {testMode ? (
                                                      <>
                                                            {Math.round(
                                                                  testMode
                                                                        .highestWpmOfATest
                                                                        .wpm
                                                            )}{" "}
                                                            wpm /{" "}
                                                            {Math.round(
                                                                  testMode
                                                                        .highestWpmOfATest
                                                                        .accuracy
                                                            )}{" "}
                                                            %
                                                      </>
                                                ) : (
                                                      "not attempted"
                                                )}
                                          </td>
                                          <td className={styles["td"]}>
                                                {testMode ? (
                                                      <>
                                                            {Math.round(
                                                                  testMode.totalNumberOfFinishedTests
                                                            )}
                                                      </>
                                                ) : (
                                                      "not attempted"
                                                )}
                                          </td>
                                    </tr>
                              </tbody>
                        </table>
                  </div>
            </div>
      );
};
